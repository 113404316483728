import { isClientSide } from '@canalplus/mycanal-commons';
import { Middleware } from 'redux';
import { fetchLiveTVAccessibleChannelsService } from '../../services/fetchLiveTVChannelsService/fetchLiveTVAccessibleChannelsService';
import { playerConfigSelector } from '../slices/player-selectors';
import { getPassToken, setAccessibleChannels } from '../slices/user';
import type { IState } from '../types/State-type';

/**
 * Fetch livetv accessible channels when a new pass token is dispatched. This middleware is
 * triggered :
 * - On client boot, once we set the pass token
 * - On pass tokens renewal
 */
export const fetchInitLiveTVMiddleware: Middleware<object, IState, Redux.Dispatch> =
  (store) => (next) => async (action) => {
    next(action);

    if (isClientSide() && getPassToken.match(action)) {
      const playerConfig = playerConfigSelector(store.getState());

      const accessibleChannels = await fetchLiveTVAccessibleChannelsService(playerConfig, {
        passToken: action.payload,
      });

      store.dispatch(setAccessibleChannels(accessibleChannels));
    }
  };
