import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export type AdultState = {
  /** Whether we are on an adult page */
  active: boolean;

  /** Whether we are still on an adult page */
  adultBrowsing: boolean;

  adultStatus: {
    enabled: boolean;
    salt?: string;
    encryptionMethod?: number;
  };

  /** Whether the user has read the adult disclaimer */
  disclaimerRead: boolean;

  /** The error message */
  error: string;

  /** Whether we have fetched the user's adult activation and code */
  initialized: boolean;

  isParentalCodeActivatedForUserOverloadableContents: boolean;
};

export const initialState = {
  active: false,
  adultBrowsing: false,
  adultStatus: {
    enabled: false,
  },
  disclaimerRead: false,
  error: '',
  initialized: false,
  isParentalCodeActivatedForUserOverloadableContents: false,
} satisfies AdultState as AdultState;

const adultSlice = createSlice({
  name: 'adult',
  initialState,
  reducers: {
    /** Triggered when the adult disclaimer has been read */
    hasReadDisclaimer: (state) => {
      return {
        ...state,
        disclaimerRead: true,
      };
    },

    /** Triggered when browsing in adult pages is reset */
    resetAdultBrowsing: (state) => {
      return {
        ...state,
        active: false,
        adultBrowsing: false,
        disclaimerRead: false,
      };
    },

    /** Triggered when the adult error has been reset */
    resetAdultError: (state) => {
      return {
        ...state,
        error: '',
      };
    },

    /** Triggered when view is authorized in adult pages */
    updateAdultActive: (state) => {
      return {
        ...state,
        active: true,
      };
    },

    /** Triggered when browsing is authorized in adult pages */
    updateAdultBrowsing: (state) => {
      return {
        ...state,
        adultBrowsing: true,
      };
    },

    /** Triggered when the adult error has been updated */
    updateAdultError: (state, action: PayloadAction<AdultState['error']>) => {
      return {
        ...state,
        error: action.payload,
      };
    },

    /** Triggered when the adult status has been updated */
    updateAdultStatus: (state, action: PayloadAction<AdultState['adultStatus']['enabled']>) => {
      return {
        ...state,
        initialized: true,
        adultStatus: {
          enabled: action.payload,
        },
      };
    },
  },
});

export const {
  hasReadDisclaimer,
  resetAdultBrowsing,
  resetAdultError,
  updateAdultActive,
  updateAdultBrowsing,
  updateAdultError,
  updateAdultStatus,
} = adultSlice.actions;

export const adultReducer = adultSlice.reducer;

export type AdultActions = ReturnType<(typeof adultSlice.actions)[keyof typeof adultSlice.actions]>;
