import { IInitLiveTVData, IParsedChannels } from '@canalplus/oneplayer-types';
import LocalStorage from './LocalStorage';

const TTL = 300_000; // 300 seconds expiry time

interface ISavedData extends Omit<IInitLiveTVData, 'channels'> {
  channels: Array<[string, IParsedChannels]>;
  expiryTime: number;
  passToken: string;
}

class DataStorage extends LocalStorage {
  private static dataInstance: DataStorage | undefined;

  public static getInstance(): DataStorage {
    if (!DataStorage.dataInstance) {
      DataStorage.dataInstance = new DataStorage();
    }

    return DataStorage.dataInstance;
  }

  constructor() {
    super('data');
  }

  async saveInitLiveTVData(
    data: IInitLiveTVData,
    passToken: string,
    initLiveTvVersion: string,
  ): Promise<void> {
    const now = new Date();

    const savedData: ISavedData = {
      ...data,
      // Channels is ES6 Map object (not stringifiable), format it to Array
      channels: Array.from(data.channels.entries()),
      // Add expiryTime
      expiryTime: now.getTime() + TTL,
      passToken,
    };

    await this.setItem(`initLiveTVData:${initLiveTvVersion}`, savedData);
  }

  async getSavedInitLiveTVData(
    passToken: string,
    initLiveTvVersion: string,
  ): Promise<IInitLiveTVData | null> {
    const savedData: ISavedData = await this.getItem(
      `initLiveTVData:${initLiveTvVersion}`,
    );

    if (!savedData) {
      return null;
    }

    const now = new Date();
    const isExpired = now.getTime() > savedData.expiryTime;
    const isCurrentUser = savedData.passToken === passToken;

    if (isExpired || !isCurrentUser) {
      return null;
    }

    // Remove expiryTime and passToken from savedData
    const { expiryTime, passToken: _pass, ...rest } = savedData;

    // Format channels back to Map object
    const initLiveTVData: IInitLiveTVData = {
      ...rest,
      channels: new Map(rest.channels),
    };

    return initLiveTVData;
  }
}

export const dataStorage = DataStorage.getInstance();

export default DataStorage;
