import { ThemeColor } from '../../constants/themeColor';
import { FormatMultiLivePlayerData } from '../../helpers/liveTV/liveTV-helper';
import {
  sendPlayTrackingEvent,
  sendTrackingHitOnPage,
  sendTrackingOnCTAEvent,
} from '../../helpers/tracking/tracking-helper';
import {
  offerLocationSelector,
  slideshowNotificationsTrackingSelector,
} from '../../selectors/application/application-selectors';
import { abTestingPopulationSelector, isKidsProfileSelector, segTypeSelector } from '../../store/slices/user-selectors';
import { AllActionsTypes } from '../reducers/actions/All-actions-types';
import { updateSlideshowTracking, updateSlideshowTrackingContext } from '../reducers/actions/Application-actions';
import { DownloadToGoTypeKeys } from '../reducers/actions/DownloadToGo-actions';
import { displayTVModeSelector } from '../slices/displayMode-selectors';
import { closeImmersive } from '../slices/immersive';
import { pageURLSelector } from '../slices/page-selectors';
import { sendLaunchPlayerTracking } from '../slices/player';
import { infoTrackingSelector, playerSettingsSelector, trackingContextSelector } from '../slices/player-selectors';
import { closeImmersivePageTrackingSelector, findTrackingSelector } from '../slices/tracking-selectors';

/**
 * Handles all side effects for tracking
 */
export const trackingMiddleware: Redux.Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const state = getState();

    const abTestingPopulation = abTestingPopulationSelector(state);
    const isKids = isKidsProfileSelector(state);
    const isTvDevice = displayTVModeSelector(state);
    const offerLocation = offerLocationSelector(state);
    const pageURL = pageURLSelector(state);
    const segType = segTypeSelector(state);
    const tracking = findTrackingSelector(state);
    const closeImmersiveTracking = closeImmersivePageTrackingSelector(state);

    switch ((action as AllActionsTypes).type) {
      case sendLaunchPlayerTracking.type: {
        const settings = playerSettingsSelector(state);
        const infoTracking = infoTrackingSelector(state);
        const trackingContext = trackingContextSelector(state);
        const slideshowTracking = slideshowNotificationsTrackingSelector(state);

        const { data, isMultilive, isStartOverAsReplayAvailable, startFromBeginning } =
          (action as ReturnType<typeof sendLaunchPlayerTracking>).payload || {};

        const { ads } = data || {};
        const multiliveEpgIds = isMultilive
          ? ((data as FormatMultiLivePlayerData) || []).map((channel) => parseInt(channel.content, 10))
          : [];

        if (data) {
          sendPlayTrackingEvent({
            abTestingPopulation,
            infoTracking,
            isKids,
            isMultilive,
            isStartOverAsReplayAvailable,
            multiliveEpgIds,
            offerLocation,
            pageURL,
            platform: settings?.platform,
            playerAdsPath: ads?.path,
            segType,
            startFromBeginning,
            themeColor: ThemeColor.Dark,
            tracking,
            trackingContext,
            version: $_BUILD_APP_VERSION,
          });
        }

        // important reset tracking slideshow to have good context for next tracking
        if (slideshowTracking) {
          dispatch(updateSlideshowTracking(undefined));
          dispatch(updateSlideshowTrackingContext(undefined));
        }
        break;
      }

      case DownloadToGoTypeKeys.OPEN_DOWNLOAD_TO_GO: {
        sendTrackingOnCTAEvent({
          abTestingPopulation,
          isKids,
          offerLocation,
          pageURL,
          segType,
          themeColor: ThemeColor.Dark,
          tracking,
          type: DownloadToGoTypeKeys.OPEN_DOWNLOAD_TO_GO,
          version: $_BUILD_APP_VERSION,
          isTvDevice,
        });
        break;
      }

      case closeImmersive.type: {
        sendTrackingHitOnPage(
          {
            abTestingPopulation,
            isKids,
            offerLocation,
            pageURL,
            segType,
            themeColor: ThemeColor.Dark,
            tracking: closeImmersiveTracking,
            version: $_BUILD_APP_VERSION,
          },
          isTvDevice
        );
        break;
      }

      default:
        break;
    }

    return next(action);
  };
