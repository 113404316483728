/**
 * Inject a script by URL to the dom
 * @param  {string} scriptUrl - URL of the script to load
 * @param  {object} [options={}] - A set of options
 * @param  {function} [options.done=Function.prototype] - A callback to call once script is loaded
 * @param  {object} [options.attributes={}] - A set of additional attributes for the <script /> tag
 */
export function injectScriptToDom(scriptUrl: string, { done = Function.prototype, attributes = {} }: any = {}): void {
  const script = document.createElement('script');
  script.setAttribute('src', scriptUrl);
  Object.keys(attributes).forEach((key) => script.setAttribute(key, attributes[key]));
  script.addEventListener('load', done);
  document.head.appendChild(script);
}
