import { DisplayMode } from '@canalplus/mycanal-commons';
import { Middleware } from 'redux';
import { ModalTypes } from '../../constants/modalTypes';
import { modalTypeSelector } from '../../store/slices/modal-selectors';
import { openImmersive } from '../slices/immersive';
import { isImmersiveSelector } from '../slices/immersive-selectors';
import { closeModal, openModal } from '../slices/modal';
import { isSearchActiveSelector } from '../slices/search-selectors';
import { blockAppScroll, setDisplayMode } from '../slices/ui';
import { noScrollSelector } from '../slices/ui-selectors';
import { IState } from '../types/State-type';

export const uiMiddleware: Middleware<object, IState, Redux.Dispatch> =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    const state = getState();

    if (setDisplayMode.match(action)) {
      const noScroll = noScrollSelector(state);
      if (action.payload === DisplayMode.FULLWINDOWED && !noScroll) {
        dispatch(blockAppScroll(true));
      } else if (noScroll && !isImmersiveSelector(state) && !isSearchActiveSelector(state)) {
        dispatch(blockAppScroll(false));
      }

      return next(action);
    }

    if (openImmersive.match(action) || openModal.match(action)) {
      dispatch(blockAppScroll(true));

      return next(action);
    }

    if (closeModal.match(action)) {
      if (!isImmersiveSelector(state)) {
        dispatch(blockAppScroll(false));
      }

      if (modalTypeSelector(state) !== ModalTypes.STARTOVER_MODAL) {
        dispatch(setDisplayMode(DisplayMode.WINDOWED));
      }

      return next(action);
    }

    return next(action);
  };
