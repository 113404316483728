import { getQsValueByKey } from '@canalplus/mycanal-commons';
import { createSelector } from '@reduxjs/toolkit';
import { Queries } from '../../constants/url';
import { IState } from '../types/State-type';

const routingSelector = (state: IState) => state?.router;

export const routingLocationSelector = createSelector(routingSelector, (routing) => routing?.location);

export const pathnameSelector = createSelector(routingLocationSelector, (location) => location?.pathname);

export const locationSearchSelector = createSelector(routingLocationSelector, (location) => location?.search || '');

const routerStateSelector = createSelector(routingLocationSelector, (location) => location?.state);

const onClickPageSelector = createSelector(routerStateSelector, (routerState) => routerState?.page?.mainOnClick);

const onClickImmersiveSelector = createSelector(
  routerStateSelector,
  (routerState) => routerState?.immersive?.mainOnClick
);

export const routerOnClickSelector = createSelector(
  onClickImmersiveSelector,
  onClickPageSelector,
  (onClickImmersive, onClickPage) => onClickImmersive || onClickPage
);

export const routingContextSelector = createSelector(
  routerStateSelector,
  (routerState) => routerState?.immersive?.context || routerState?.page?.context
);

export const locationSearchChannelSelector = createSelector(locationSearchSelector, (locationSearch) => {
  const channelId = getQsValueByKey(locationSearch, Queries.Channel);
  if (channelId) {
    return parseInt(channelId, 10);
  }
  return null;
});
