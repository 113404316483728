import type { Agent } from 'https';
import type { HttpsProxyAgent } from 'https-proxy-agent';
import fetch from 'isomorphic-fetch';
import { timeoutPromise } from './helpers/timeoutPromise';

export type FetchOptions = RequestInit & {
  agent?: HttpsProxyAgent<string> | Agent;
  timeout?: number;
};

/** Since isomorphic fetch does not handle timeout, this method wraps a timeout around it in an isomorphic way */
export function customFetch(url: string, options: FetchOptions = {}): Promise<Response> {
  const { timeout = 0 } = options;

  if (timeout) {
    return timeoutPromise(url, options, timeout);
  }

  return fetch(url, options);
}
