import { ALL_SUPPORTED_BREAKPOINTS, BREAKPOINT_LABELS_MAPPING, BREAKPOINT_WIDE } from '../../constants/responsive';

export const getBreakpointLabel = (screenWidth: number): string => {
  for (let i = 1; i < ALL_SUPPORTED_BREAKPOINTS.length; i += 1) {
    const floor = ALL_SUPPORTED_BREAKPOINTS[i - 1];
    const ceiling = ALL_SUPPORTED_BREAKPOINTS[i];
    if (screenWidth >= floor && screenWidth < ceiling) {
      return BREAKPOINT_LABELS_MAPPING[floor];
    }
  }
  // default to "wide"
  return BREAKPOINT_LABELS_MAPPING[BREAKPOINT_WIDE];
};

/**
 * Helper function that shrinks width and height to maintain aspect ratio. Useful for responsive design.
 * @param aspectRatio - The aspect ratio of the element. E.g. "16:9"
 * @param containerWidth - The width of the container.
 * @param containerHeight - The Height of the container.
 * @returns The available width and height of the element, within the aspect ratio and container's dimensions.
 * @example
 *   getMinRatioWidth('16:9', 1920, 1080); //  { width: 1920, height: 1080 }
 *   getMinRatioWidth('16:9', 1920, 720); // { width: 1280, height: 720 }
 *   getMinRatioWidth('16:9', 1280, 1080); // { width: 1280, height: 720 }
 */
export const getMinRatioDimensions = ({
  aspectRatio = '16:9',
  containerWidth,
  containerHeight,
}: {
  aspectRatio: string;
  containerWidth?: number;
  containerHeight?: number;
}): { width: number; height: number } => {
  if (!containerWidth || !containerHeight) {
    return { width: 0, height: 0 };
  }
  const [aspectWidth, aspectHeight] = aspectRatio.split(':').map(Number);
  const ratio = aspectWidth / aspectHeight;
  const minWidth = Math.min(containerHeight * ratio, containerWidth);
  const minHeight = minWidth / ratio;
  return { width: Math.round(minWidth), height: Math.round(minHeight) };
};
