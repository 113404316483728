import { LogLevel } from '../../types';

/**
 * Return if the given log level should be logged
 * Log levels sorted by importance
 * - error
 * - warn
 * - info
 * - debug
 * - verbose
 */
export const isRelevant = (level: LogLevel, minLevel: LogLevel): boolean => {
  if (minLevel === 'error' && level === 'error') {
    return true;
  }
  if (minLevel === 'warn' && (level === 'error' || level === 'warn')) {
    return true;
  }
  if (minLevel === 'info' && (level === 'error' || level === 'warn' || level === 'info')) {
    return true;
  }
  if (minLevel === 'verbose' && level !== 'debug') {
    return true;
  }
  if (minLevel === 'debug') {
    return true;
  }

  return false;
};
