import { ApiV2Authenticate } from '@dce-front/hodor-types';
import { ApiV2PageSectionsList } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/sections_list';

// TODO WAITING FOR HODOR TYPINGS
export type MockApiV2UserMenuLanguages = {
  _functionalTestsIgnored: boolean;
  currentPage: {
    _functionalTestsIgnored: boolean;
    path: string;
    displayTemplate: string;
  };
  languages: {
    language: string;
    languagePath: string;
    languageLabel: string;
    offerZone: string;
    locale: string;
  }[][];
};

/** Hodor `ApiV2NavigationItem` type dictionary */
export const NavigationItemType = {
  CurrentProfile: 'currentProfile',
  Text: 'text',
} as const;

export type AuthenticateWithUserMenuExtended = ApiV2Authenticate & {
  userMenuExtended: ApiV2PageSectionsList;
  userMenuLanguages?: MockApiV2UserMenuLanguages;
};
