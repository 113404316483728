import { IContent } from '@canalplus/mycanal-sdk';
import { IAPIConfigRaw, IAPICredentialsRaw } from '@canalplus/oneplayer-types';
import { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PlayerType } from '../../constants/playerTypes';
import { FormatMultiLivePlayerData, ILiveGridPlayerData } from '../../helpers/liveTV/liveTV-helper';
import { IPlayInfoTracking } from '../../helpers/tracking/types';

export type PlayerInstance = {
  initialized: boolean;
  getAllProgramGenres: () => { id: number; name: string }[];
  getAvailableChannels: (activeThemeId?: number) => number[];
  getCurrentTime: () => number;
  getContentInfo: () => Record<string, unknown>;
  zapTo: (id?: number, date?: Date) => void;
  setAdData: ({
    gdpr,
    targetedAds,
  }: {
    gdpr: number;
    targetedAds: string;
    consentString?: string;
    targeting?: string;
  }) => void;
  loadVideo: (settings: Partial<IAPIConfigRaw>, credentials: IAPICredentialsRaw, container?: Node | null) => void;
  destroy: () => void;
  player: Record<string, never>;
  container: Node;
};

export type PlayerState = {
  playerType: PlayerType;
  playerInstance: PlayerInstance;
  playerElement: HTMLDivElement | null;
  shouldFallback?: boolean;
  open?: boolean;
  openFullScreen?: boolean;
  fatalError?: boolean;
  isFullScreen?: boolean;
  trackingContext?: ApiV2Context & {
    season_number?: number;
    episode_number?: number;
  };
  infoTracking?: IPlayInfoTracking;
  isSupportedDrm?: boolean;
  isSupportedBrowser?: boolean;
  settings: IAPIConfigRaw | null;
  data?: (Record<string, unknown> | ILiveGridPlayerData | FormatMultiLivePlayerData | IContent) & {
    ads?: { path?: string | null };
  };
  /**
   * It is the timecode in ms of video position playback before stop it
   */
  lastCurrentTime?: number;
  lastWatchedEpisodeStreamId?: string;
};

const initialState: PlayerState = {
  playerInstance: {} as any, // current player instance
  playerElement: null, // dom element that wraps the instance. In case of lazy instanciation, this is always null
  playerType: null,
  settings: null,
  fatalError: false,
  openFullScreen: false,
  trackingContext: {},
  infoTracking: {},
  data: {},
};

const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    killPlayer(
      state,
      action: PayloadAction<
        | {
            lastCurrentTime: PlayerState['lastCurrentTime'];
            lastWatchedEpisodeStreamId: PlayerState['lastWatchedEpisodeStreamId'];
          }
        | undefined
      >
    ) {
      return {
        ...state,
        settings: null,
        open: false,
        isFullScreen: false,
        lastCurrentTime: action.payload?.lastCurrentTime,
        lastWatchedEpisodeStreamId: action.payload?.lastWatchedEpisodeStreamId,
      };
    },

    setPlayerType(state, action: PayloadAction<Pick<PlayerState, 'playerType'>>) {
      return { ...state, playerType: action.payload.playerType };
    },

    changeChannel(state, _action: PayloadAction<{ epgID: number }>) {
      return state;
    },

    initializePlayer(state, action: PayloadAction<Pick<PlayerState, 'playerInstance'>, string>) {
      return {
        ...state,
        playerInstance: action.payload.playerInstance as any,
        fatalError: false,
      };
    },

    openFullScreen(state, action: PayloadAction<Pick<PlayerState, 'trackingContext'>>) {
      return {
        ...state,
        isFullScreen: true,
        trackingContext: action.payload.trackingContext,
      };
    },

    destroyPlayer(state) {
      // Beware, we should not clean the player instance here.
      return { ...state, isFullScreen: false };
    },

    startPlayer(
      state,
      action: PayloadAction<Pick<PlayerState, 'settings' | 'infoTracking'> & { event?: React.SyntheticEvent }>
    ) {
      return {
        ...state,
        settings: action.payload.settings,
        infoTracking: action.payload.infoTracking,
        open: true,
      };
    },

    sendLaunchPlayerTracking(
      state,
      _action: PayloadAction<
        Pick<PlayerState, 'data'> & {
          startFromBeginning?: boolean;
          isStartOverAsReplayAvailable?: boolean;
          isMultilive?: boolean;
        }
      >
    ) {
      return state;
    },
  },
});

export const {
  changeChannel,
  destroyPlayer,
  initializePlayer,
  killPlayer,
  openFullScreen,
  sendLaunchPlayerTracking,
  setPlayerType,
  startPlayer,
} = playerSlice.actions;

export const playerReducer = playerSlice.reducer;

export type PlayerActions = ReturnType<(typeof playerSlice.actions)[keyof typeof playerSlice.actions]>;
