import { getUrlOrigin } from '@canalplus/mycanal-commons';
import { BusinessTypes } from '@canalplus/oneplayer-constants';
import { Platform } from '@canalplus/sdk-core';
import { DiveTokensBrands } from '@dce-front/dive-tokens';
import { ApiV2NavigationItem } from '@dce-front/hodor-types/api/v2/authenticate/definitions';
import Cookies from 'js-cookie';
import { Overlay } from '../../../config/application/types';
import { ApplicationTheme, Dark, Light } from '../../constants/applicationTheme';
import { CookieKey } from '../../constants/cookie';
import { getPublicConfig } from '../config/config-helper';

const defaultDeviceType = BusinessTypes.DEVICE_TYPES.DESKTOP_MOBILE;

const DEVICE_TYPES = {
  [Platform.FireTV]: defaultDeviceType,
  [Platform.G9]: defaultDeviceType,
  [Platform.G9Mini]: defaultDeviceType,
  [Platform.G9Sat]: defaultDeviceType,
  [Platform.G9LCPI]: defaultDeviceType,
  [Platform.G9LCPISat]: defaultDeviceType,
  [Platform.G10]: defaultDeviceType,
  [Platform.G10LCPI]: defaultDeviceType,
  [Platform.G11]: defaultDeviceType,
  [Platform.Hisense]: BusinessTypes.DEVICE_TYPES.HISENSE,
  [Platform.LG]: BusinessTypes.DEVICE_TYPES.LG,
  [Platform.OrangeKey]: defaultDeviceType,
  [Platform.OrangeManhattan]: defaultDeviceType,
  [Platform.OrangeMib4]: defaultDeviceType,
  [Platform.OrangePlay]: defaultDeviceType,
  [Platform.OrangeStellar]: defaultDeviceType,
  [Platform.Philips]: 'philips',
  [Platform.PlayStation4]: defaultDeviceType,
  [Platform.PlayStation5]: defaultDeviceType,
  [Platform.Samsung]: BusinessTypes.DEVICE_TYPES.SAMSUNG,
  [Platform.SFR7IPTV]: defaultDeviceType,
  [Platform.SFR8IPTV]: defaultDeviceType,
  [Platform.SFR8Cable]: defaultDeviceType,
  [Platform.Web]: defaultDeviceType,
} as const satisfies Record<Platform, BusinessTypes.DEVICE_TYPES | 'philips'>;

const defaultDeviceId = '3';

const DEVICE_IDS = {
  [Platform.FireTV]: defaultDeviceId,
  [Platform.G9]: defaultDeviceId,
  [Platform.G9Mini]: defaultDeviceId,
  [Platform.G9Sat]: defaultDeviceId,
  [Platform.G9LCPI]: defaultDeviceId,
  [Platform.G9LCPISat]: defaultDeviceId,
  [Platform.G10]: defaultDeviceId,
  [Platform.G10LCPI]: defaultDeviceId,
  [Platform.G11]: defaultDeviceId,
  [Platform.Hisense]: '18',
  [Platform.LG]: '16',
  [Platform.OrangeKey]: defaultDeviceId,
  [Platform.OrangeManhattan]: defaultDeviceId,
  [Platform.OrangeMib4]: defaultDeviceId,
  [Platform.OrangePlay]: defaultDeviceId,
  [Platform.OrangeStellar]: defaultDeviceId,
  [Platform.Philips]: '80',
  [Platform.PlayStation4]: '14',
  [Platform.PlayStation5]: '14',
  [Platform.Samsung]: '15',
  [Platform.SFR7IPTV]: defaultDeviceId,
  [Platform.SFR8IPTV]: defaultDeviceId,
  [Platform.SFR8Cable]: defaultDeviceId,
  [Platform.Web]: defaultDeviceId,
} satisfies Record<Platform, string>;

export const OVERLAY_APPLICATION_THEMES = {
  [Overlay.TIM]: ApplicationTheme.Tim,
  [Overlay.TVODStandalone]: ApplicationTheme.Tvod,
  [Overlay.MyCanal]: ApplicationTheme.Default,
} as const satisfies Record<Overlay, ApplicationTheme>;

export const removeTokenCmsCookie = (domain: string): void => Cookies.remove(CookieKey.TokenCMS, { domain, path: '/' });

/**
 * Replace placeholders in external websites URL: {zone} => offerLocation, {passId} => passId...
 *
 * @param  {array} navigation   - Navigation items
 * @param  {string} placeholder  - placeholder to replace
 * @param  {string} value       - replace value
 */
export const replacePlaceholders = (
  navigation: ApiV2NavigationItem[],
  placeholder: string,
  value: string = ''
): any => {
  const template = new RegExp(`{${placeholder}}`);

  return (navigation || []).map((item) => {
    if (item.onClick?.displayTemplate === getPublicConfig().TEMPLATE.EXTERNAL_SITE && item?.onClick?.URLWebsite) {
      item.onClick.URLWebsite = item.onClick.URLWebsite.replace(template, value);
    }
    return item;
  });
};

/**
 * Returns the current theme combining dark or light mode, app theme and DiveTokensBrands
 * @param isDarkMode Whether the app is in dark or light mode
 * @param mainTheme Theme of the app (e.g. `default` for myCANAL, `tvod`, `telecomitalia`...)
 */
export const getCurrentTheme = (isDarkMode: boolean, mainTheme: ApplicationTheme, isBlueTim?: boolean): string => {
  const currentTheme = isDarkMode ? Dark : Light;

  switch (mainTheme) {
    case ApplicationTheme.Tvod:
      return `${mainTheme} ${currentTheme} ${DiveTokensBrands.Vod}`;
    case ApplicationTheme.Tim:
      if (isBlueTim) {
        return `${mainTheme} ${currentTheme} ${DiveTokensBrands.NewTim}`;
      } else {
        return `${mainTheme} ${currentTheme} ${DiveTokensBrands.Tim}`;
      }
    case ApplicationTheme.Default:
    default:
      return `${currentTheme} ${DiveTokensBrands.MyCanal}`;
  }
};

/** dive-token files pathnames used for testing purposes */
export const DT_PATHNAME = {
  unbranded: {
    /** Tailwind utilities (css classes) used in `@canalplus/dive` UI components */
    variables: '@canalplus/dive/dist/css/theme/variables.css',
    /** Colors Globals tokens, required by branded Alias tokens and Component-specific tokens */
    globals: '@dce-front/dive-tokens/web/css/colors.globals.css',
    opacity: '@dce-front/dive-tokens/web/css/opacity.css',
    border: '@dce-front/dive-tokens/web/css/border.css',
    breakpoint: '@dce-front/dive-tokens/web/css/breakpoint.css',
    radius: '@dce-front/dive-tokens/web/css/radius.css',
    spacing: '@dce-front/dive-tokens/web/css/spacing.css',
    typographyFamily: '@dce-front/dive-tokens/web/css/typography.family.css',
    typographyLineHeight: '@dce-front/dive-tokens/web/css/typography.line-height.css',
    typographySize: '@dce-front/dive-tokens/web/css/typography.size.css',
    typographyWeight: '@dce-front/dive-tokens/web/css/typography.weight.css',
  },
  branded: {
    tvod: '@canalplus/dive/dist/css/variables/vod-dark.css',
    default: '@canalplus/dive/dist/css/variables/canal-dark.css',
    telecomitalia: '@canalplus/dive/dist/css/variables/telecomitalia.css',
  },
} as const satisfies {
  unbranded: Record<string, string>;
  branded: Record<ApplicationTheme, string>;
};

/**
 * Dynamically imports [dive-tokens](https://gitlab.canal-plus.com/dce-front/dive/dive-tokens) CSS files for both:
 * - Mandatory tokens (brand-agnostic).
 * - Branded tokens, based on `themeOrBrand` parameter.
 *
 * @param themeOrBrand The theme or brand to import dive-tokens for
 * @returns A promise that resolves to when all dive-tokens have been imported. For testing purposes, the resolved value is an array of the successfully imported tokens pathnames.
 * @see {@link https://webpack.js.org/api/module-methods/#magic-comments `webpackChunkName` magic comments used for SSR chunk retrieval of `@canalplus/dive` css files}
 */
export async function importDiveTokens(
  isBlueTim: boolean,
  themeOrBrand: ApplicationTheme | DiveTokensBrands = ApplicationTheme.Default
): Promise<string[]> {
  const dtImports = [
    import(/* webpackChunkName: "dive-variables" */ '@canalplus/dive/dist/css/theme/variables.css').then(
      () => DT_PATHNAME.unbranded.variables
    ),
    import(/* webpackChunkName: "dt-globals" */ '@dce-front/dive-tokens/web/css/colors.globals.css').then(
      () => DT_PATHNAME.unbranded.globals
    ),
    import(/* webpackChunkName: "dt-opacity" */ '@dce-front/dive-tokens/web/css/opacity.css').then(
      () => DT_PATHNAME.unbranded.opacity
    ),
    import(/* webpackChunkName: "dt-border" */ '@dce-front/dive-tokens/web/css/border.css').then(
      () => DT_PATHNAME.unbranded.border
    ),
    import(/* webpackChunkName: "dt-breakpoint" */ '@dce-front/dive-tokens/web/css/breakpoint.css').then(
      () => DT_PATHNAME.unbranded.breakpoint
    ),
    import(/* webpackChunkName: "dt-radius" */ '@dce-front/dive-tokens/web/css/radius.css').then(
      () => DT_PATHNAME.unbranded.radius
    ),
    import(/* webpackChunkName: "dt-spacing" */ '@dce-front/dive-tokens/web/css/spacing.css').then(
      () => DT_PATHNAME.unbranded.spacing
    ),
    import(/* webpackChunkName: "dt-typo-family" */ '@dce-front/dive-tokens/web/css/typography.family.css').then(
      () => DT_PATHNAME.unbranded.typographyFamily
    ),
    import(
      /* webpackChunkName: "dt-typo-line-height" */ '@dce-front/dive-tokens/web/css/typography.line-height.css'
    ).then(() => DT_PATHNAME.unbranded.typographyLineHeight),
    import(/* webpackChunkName: "dt-typo-size" */ '@dce-front/dive-tokens/web/css/typography.size.css').then(
      () => DT_PATHNAME.unbranded.typographySize
    ),
    import(/* webpackChunkName: "dt-typo-weight" */ '@dce-front/dive-tokens/web/css/typography.weight.css').then(
      () => DT_PATHNAME.unbranded.typographyWeight
    ),
  ] satisfies Promise<string>[];

  switch (themeOrBrand) {
    case ApplicationTheme.Default:
      dtImports.push(
        import(/* webpackChunkName: "dive-default" */ '@canalplus/dive/dist/css/variables/canal-dark.css').then(
          () => DT_PATHNAME.branded.default
        )
      );
      break;
    case ApplicationTheme.Tim:
      if (isBlueTim) {
        await import(
          /* webpackChunkName: "dive-telecomitalia" */ '@dce-front/dive-tokens/web/css/colors.theme.new-tim.css'
        );
      } else {
        dtImports.push(
          import(
            /* webpackChunkName: "dive-telecomitalia" */ '@canalplus/dive/dist/css/variables/telecomitalia.css'
          ).then(() => DT_PATHNAME.branded.telecomitalia)
        );
      }
      break;
    case ApplicationTheme.Tvod:
      dtImports.push(
        import(/* webpackChunkName: "dive-tvod" */ '@canalplus/dive/dist/css/variables/vod-dark.css').then(
          () => DT_PATHNAME.branded.tvod
        )
      );
      break;
    default:
      break;
  }

  return Promise.all(dtImports);
}

export const isTim = getPublicConfig().overlay === Overlay.TIM;

/**
 * Get the device type to send to one player
 */
export const getDeviceTypeFromPlatform = (platform: Platform): BusinessTypes.DEVICE_TYPES | 'philips' =>
  DEVICE_TYPES[platform];

/**
 * Get the device id to send to one player
 */
export const getDeviceIdFromPlatform = (platform: Platform): string | undefined => {
  const deviceId = DEVICE_IDS[platform];

  // In onePlayer, if deviceId is undefined, it is set to 32 for Safari only or 3 by default
  return deviceId !== defaultDeviceId ? deviceId : undefined;
};

/**
 * Check if the path is a first level page
 * @param firstLevelPageList array of first level path
 * @param offerLocation string of offerLocation
 * @param path path to test
 * @returns true or false
 */
export const isFirstLevelPage = ({
  firstLevelPageList,
  offerLocation,
  path,
}: {
  firstLevelPageList: string[];
  offerLocation: string;
  path: string;
}): boolean => {
  // Remove url parameters
  const formattedPath = getUrlOrigin(path);

  return (
    firstLevelPageList.includes(formattedPath) ||
    // In the event of a fallback page (Hodor 404 return), the user must be able to navigate to another page from the header
    formattedPath?.startsWith('/fallback') ||
    // Handle home page
    formattedPath === '/' ||
    formattedPath === `/${offerLocation}/` ||
    false
  );
};
