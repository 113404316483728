import { getFeatureToggleClientPerfMeasures } from '../../selectors/application/application-selectors';
import type { IState } from '../../store/types/State-type';
import { isPerformanceApiEnabled } from './isPerformanceApiEnabled';

/**
 * Use `mark()` method from the **Performance API.**\
 * Depending on the state to know if the **ClientPerfMeasures** is enabled or not.
 *
 * **MarkName** dictionary that we're using:
 *
 * - **event-fetched**: just after the network call.
 * - **event-start**: just before the network call.
 * - **focus**: once the first focus is given via one-navigation.
 * - **hodor-authenticate-fetched**: after fetching Hodor **authenticate.**
 * - **hodor-init-fetched**: after fetching Hodor **init.**
 * - **hodor-requestpage-fetched**: after fetching the first Hodor **Requestpage.**
 * - **hodor-wsfrompath-fetched**: after fetching the first Hodor **Wsfrompath.**
 * - **pass-auth-fetched**: initial userInfo are retrieved.
 * - **queries-fetched**: initial React queries are all settled.
 * - **react-render**: once react is rendered.
 * - **start**: as soon as client script boots
 */
export const markPerformance = (markName: string, state: IState): void => {
  const featClientPerfMeasures = getFeatureToggleClientPerfMeasures(state);

  if (isPerformanceApiEnabled(featClientPerfMeasures)) {
    performance.mark(markName);
  }
};
