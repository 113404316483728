import parse from 'ua-parser-js';

/**
 * Mobile Detection
 *
 * In charge to return
 * the device by comparing patterns against a given User-Agent string.
 */

const getBrowser = (): string | undefined => {
  const userAgent = parse(window.navigator.userAgent);

  return userAgent.browser.name;
};
/**  Is it an SSR iOS device  ? */
export const isIOSServerSide = (userAgent?: string): any => {
  const parsedUserAgent = parse(userAgent);

  return parsedUserAgent.os.name === 'iOS';
};

const getOS = (): string | undefined => {
  const userAgent = parse(window.navigator.userAgent);

  return userAgent.os.name;
};

/** Is it an Android mobile browser ? */
const isAndroid = (): boolean => {
  return (getOS() || '').startsWith('Android');
};

/** Is it an ios mobile browser ? */
const isIOS = (): boolean => {
  return (getOS() || '').startsWith('iOS');
};

/** Is it an Windows Phone mobile browser ? */
const isWindowsPhone = (): boolean =>
  (getOS() || '').startsWith('Windows Phone') || (getOS() || '').startsWith('Windows Mobile');

/** Is it a mobile browser ? */
const isAnyMobile = (): boolean => isAndroid() || isIOS() || isWindowsPhone();

/** Is it a Windows Desktop browser ? */
export const isWindows = (): boolean => getOS() === 'Windows';

/** Is it a MacOS browser ? */
export const isMacOs = (): boolean => (getOS() || '').startsWith('Mac OS');

/** Is it a Desktop browser ? */
const isAnyDesktop = (): boolean => isWindows() || isMacOs();

/** Is the browser Safari ? */
const isSafari = (): boolean => (getBrowser() || '').includes('Safari'); // "Safari" or "Mobile Safari"

type UserAgent = {
  isBrowser: {
    isSafari: boolean;
  };
  isDesktop: {
    isAny: boolean;
    isMacOs: boolean;
    isWindows: boolean;
  };
  isMobile: {
    isAndroid: boolean;
    isAny: boolean;
    isIOS: boolean;
    isWindows: boolean;
  };
};

export const getUserAgent = (): UserAgent => ({
  isBrowser: {
    isSafari: isSafari(),
  },
  isDesktop: {
    isAny: isAnyDesktop(),
    isMacOs: isMacOs(),
    isWindows: isWindows(),
  },
  isMobile: {
    isAndroid: isAndroid(),
    isAny: isAnyMobile(),
    isIOS: isIOS(),
    isWindows: isWindowsPhone(),
  },
});

/**
 * Compare current osVersion with given version
 * Returns true if currentOsVersion>=version, false otherwise
 * @param  {string} version OS version
 * @returns {number}
 */
export const isNewerVersion = (version: string): boolean => {
  if (typeof version !== 'string') {
    throw new Error('Parameter must be of type string');
  }

  const userAgent = parse(window.navigator.userAgent);
  const currOsVersion = userAgent.os.version;

  if (!currOsVersion) {
    return false;
  }

  const currOsVersionArr = currOsVersion.split('.').map(Number);
  const versionArr = version.split('.').map(Number);
  const k = Math.min(currOsVersionArr.length, versionArr.length);

  for (let i = 0; i < k; i += 1) {
    if (currOsVersionArr[i] > versionArr[i]) {
      return true;
    }
    if (currOsVersionArr[i] < versionArr[i]) {
      return false;
    }
  }

  if (currOsVersionArr.length === versionArr.length) {
    return true;
  } else if (currOsVersionArr.length < versionArr.length) {
    return false;
  }

  return true;
};
