import { Lang, OfferLocation, OfferZone, Platform } from '@canalplus/sdk-core';
import { HodorSdkConfig } from '@canalplus/sdk-hodor';
import { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import { Tracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import { ApiV2InitResponse } from '@dce-front/hodor-types/api/v2/me/init/definitions';
import { ApiV2PageTracking } from '@dce-front/hodor-types/api/v2/page/dtos/definitions';
import { AppKey } from '../../../../config/application/types';
import type { RenderSource } from '../../../constants/renderSource';
import type { ResizeMode } from '../../../constants/resize';
import type { SettingQualityD2G } from '../../../templates/DownloadToGo/types';
import type { AuthenticateWithUserMenuExtended } from '../../../typings/hodor';
import { ApplicationStateSettings, IApplicationState, UserAgent } from '../../types/Application-type';

export enum ApplicationTypeKeys {
  APPLICATION_GET_USER_AGENT = 'APPLICATION_GET_USER_AGENT',
  APPLICATION_LOAD_D2G_SUCCESS = 'APPLICATION_LOAD_D2G_SUCCESS',
  APPLICATION_LOAD_PLAYER_SUCCESS = 'APPLICATION_LOAD_PLAYER_SUCCESS',
  APPLICATION_OVERRIDE_SETTINGS = 'APPLICATION_OVERRIDE_SETTINGS',
  APPLICATION_RENDER_SOURCE = 'APPLICATION_RENDER_SOURCE',
  APPLICATION_SERVICE_AUTHENTICATE_FETCH = 'APPLICATION_SERVICE_AUTHENTICATE_FETCH',
  APPLICATION_SERVICE_AUTHENTICATE_SUCCESS = 'APPLICATION_SERVICE_AUTHENTICATE_SUCCESS',
  APPLICATION_SET_CONFIGURATION_JSON = 'APPLICATION_SET_CONFIGURATION_JSON',
  APPLICATION_SET_CONTEXT_INSTANTIATION = 'APPLICATION_SET_CONTEXT_INSTANTIATION',
  APPLICATION_SET_HODOR_SDK_CONFIG = 'APPLICATION_SET_HODOR_SDK_CONFIG',
  APPLICATION_SET_HOSTNAME = 'APPLICATION_SET_HOSTNAME',
  APPLICATION_SET_LOCALE_FROM_HODOR = 'APPLICATION_SET_LOCALE_FROM_HODOR',
  APPLICATION_SET_RESIZE = 'APPLICATION_SET_RESIZE',
  APPLICATION_SET_SHORT_VIDEO_IDS = 'APPLICATION_SET_SHORT_VIDEO_IDS',
  APPLICATION_SET_ZONE_INFO = 'APPLICATION_SET_ZONE_INFO',
  APPLICATION_SET_INIT_TRACKING = 'APPLICATION_SET_INIT_TRACKING',
  APPLICATION_SET_SLIDESHOW_TRACKING = 'APPLICATION_SET_SLIDESHOW_TRACKING',
  APPLICATION_SET_SLIDESHOW_TRACKING_CONTEXT = 'APPLICATION_SET_SLIDESHOW_TRACKING_CONTEXT',
  FOOTER_DISPLAY = 'FOOTER_DISPLAY',
  GDPR_DISPLAY = 'GDPR_DISPLAY',
  HEADER_DISPLAY = 'HEADER_DISPLAY',
  SET_D2G_QUALITY = 'SET_D2G_QUALITY',
  SET_FIRST_LEVEL_PAGE_LIST = 'SET_FIRST_LEVEL_PAGE_LIST',
  SET_HEADER_LEVEL_PAGE_LIST = 'SET_HEADER_LEVEL_PAGE_LIST',
  SET_IN_CONTAINER = 'SET_IN_CONTAINER',
  SET_WHO_IS_WATCHING_USER_DISABLED = 'SET_WHO_IS_WATCHING_USER_DISABLED',
  SET_WHO_IS_WATCHING_USER_SESSION = 'SET_WHO_IS_WATCHING_USER_SESSION',
  SET_FEAT_WHO_IS_WATCHING = 'SET_FEAT_WHO_IS_WATCHING',
}
export interface IHideHeaderAction {
  readonly isHeaderHidden: boolean;
  readonly type: ApplicationTypeKeys.HEADER_DISPLAY;
}

/**
 * hideHeader
 * Hides Header component
 * @param {boolean} isHeaderHidden
 * @returns {object} action HEADER_DISPLAY
 */
export const hideHeader = (isHeaderHidden: boolean): IHideHeaderAction => ({
  isHeaderHidden,
  type: ApplicationTypeKeys.HEADER_DISPLAY,
});

export interface IHideFooterAction {
  readonly isFooterHidden: boolean;
  readonly type: ApplicationTypeKeys.FOOTER_DISPLAY;
}

/**
 * hideFooter
 * Hides Footer component
 * @param {boolean} isFooterHidden
 * @returns {object} action FOOTER_DISPLAY
 */
export const hideFooter = (isFooterHidden: boolean): IHideFooterAction => ({
  isFooterHidden,
  type: ApplicationTypeKeys.FOOTER_DISPLAY,
});

export interface IShowGDPRAction {
  readonly shouldShowGDPR: boolean;
  readonly type: ApplicationTypeKeys.GDPR_DISPLAY;
}

/**
 * showGDPR
 * Shows GDPR consent intro screens
 * @param {boolean} shouldShowGDPR
 * @returns {object} action GDPR_DISPLAY
 */
export const showGDPR = (shouldShowGDPR: boolean): IShowGDPRAction => ({
  shouldShowGDPR,
  type: ApplicationTypeKeys.GDPR_DISPLAY,
});

export interface ILoadPlayerSuccessAction {
  readonly isPlayerScriptLoaded: boolean;
  readonly type: ApplicationTypeKeys.APPLICATION_LOAD_PLAYER_SUCCESS;
}

/**
 * renderSource
 * Player has successfully loaded
 * @returns {object} action APPLICATION_LOAD_PLAYER_SUCCESS
 */
export const loadPlayerSuccess = (): ILoadPlayerSuccessAction => ({
  isPlayerScriptLoaded: true,
  type: ApplicationTypeKeys.APPLICATION_LOAD_PLAYER_SUCCESS,
});

export interface ILoadD2GSuccessAction {
  readonly isD2GScriptLoaded: boolean;
  readonly type: ApplicationTypeKeys.APPLICATION_LOAD_D2G_SUCCESS;
}

/**
 * renderSource
 * Download To Go has successfully loaded
 * @returns {object} action APPLICATION_LOAD_D2G_SUCCESS
 */
export const loadD2GSuccess = (): ILoadD2GSuccessAction => ({
  isD2GScriptLoaded: true,
  type: ApplicationTypeKeys.APPLICATION_LOAD_D2G_SUCCESS,
});

export interface IRenderSourceAction {
  readonly renderSource: RenderSource;
  readonly type: ApplicationTypeKeys.APPLICATION_RENDER_SOURCE;
}

/**
 * renderSource
 * Sets App's rendering source (client/server)
 * @param {string} source
 * @returns {object} action APPLICATION_RENDER_SOURCE
 */
export const renderSource = (source: RenderSource): IRenderSourceAction => ({
  renderSource: source,
  type: ApplicationTypeKeys.APPLICATION_RENDER_SOURCE,
});

export interface IServiceAuthenticateRequestAction {
  readonly isFetching: boolean;
  readonly type: ApplicationTypeKeys.APPLICATION_SERVICE_AUTHENTICATE_FETCH;
}

/**
 * serviceAuthenticateRequest
 * Requests call to Authentication services
 * Sets App's fetching status
 * @returns {object} action APPLICATION_SERVICE_AUTHENTICATE_FETCH
 */
export const serviceAuthenticateRequest = (): IServiceAuthenticateRequestAction => ({
  isFetching: true,
  type: ApplicationTypeKeys.APPLICATION_SERVICE_AUTHENTICATE_FETCH,
});

export interface IServiceAuthenticateSuccessAction {
  readonly isFetching: boolean;
  readonly payload: {
    authenticate: AuthenticateWithUserMenuExtended;
  };
  readonly type: ApplicationTypeKeys.APPLICATION_SERVICE_AUTHENTICATE_SUCCESS;
}

/**
 * Authentication was successful\
 * Sets App's fetching status & Authenticate response
 */
export const serviceAuthenticateSuccess = (
  authenticate: AuthenticateWithUserMenuExtended
): IServiceAuthenticateSuccessAction => ({
  isFetching: false,
  payload: { authenticate },
  type: ApplicationTypeKeys.APPLICATION_SERVICE_AUTHENTICATE_SUCCESS,
});

export interface IGetUserAgentAction {
  readonly userAgent: UserAgent;
  readonly type: ApplicationTypeKeys.APPLICATION_GET_USER_AGENT;
}

/**
 * setUserAgent
 * Sets App's User Agent object
 * @param {object} userAgent
 * @returns {object} action APPLICATION_GET_USER_AGENT
 */
export const setUserAgent = (userAgent: UserAgent): IGetUserAgentAction => ({
  type: ApplicationTypeKeys.APPLICATION_GET_USER_AGENT,
  userAgent,
});

export interface ISetHostnameAction {
  readonly hostname: string;
  readonly type: ApplicationTypeKeys.APPLICATION_SET_HOSTNAME;
}

/**
 * setHostname
 * Sets App's hostname
 * @param {string} hostname
 * @returns {object} action APPLICATION_SET_HOSTNAME
 */
export const setHostname = (hostname: string): ISetHostnameAction => ({
  hostname,
  type: ApplicationTypeKeys.APPLICATION_SET_HOSTNAME,
});

export interface IOverrideSettingsAction {
  readonly settings: Partial<ApplicationStateSettings>;
  readonly type: ApplicationTypeKeys.APPLICATION_OVERRIDE_SETTINGS;
}

/**
 * overrideSettings
 * Replace settings object in store
 * @param {object} settings
 * @returns {object} action APPLICATION_OVERRIDE_SETTINGS
 */
export const overrideSettings = (settings: Partial<ApplicationStateSettings>): IOverrideSettingsAction => ({
  settings,
  type: ApplicationTypeKeys.APPLICATION_OVERRIDE_SETTINGS,
});

export interface ISetZoneInfoAction {
  readonly appKey: AppKey;
  readonly offerLocation: OfferLocation;
  readonly lang: Lang;
  readonly portailId?: string;
  readonly offerZone: OfferZone;
  readonly type: ApplicationTypeKeys.APPLICATION_SET_ZONE_INFO;
}

export interface ISetLocaleFromHodor {
  readonly locale: string;
  readonly type: ApplicationTypeKeys.APPLICATION_SET_LOCALE_FROM_HODOR;
}

/**
 * setZoneInfo
 *
 * Sets offerLocation, portailId and appKey values
 * @param {string} appKey
 * @param {string} locale
 * @param {string} portailId (optional)
 * @returns {object} action ISetZoneInfoAction
 */
export const setZoneInfo = (
  appKey: AppKey,
  lang: Lang,
  offerLocation: OfferLocation,
  offerZone: OfferZone,
  portailId?: string
): ISetZoneInfoAction => ({
  appKey,
  offerLocation,
  offerZone,
  type: ApplicationTypeKeys.APPLICATION_SET_ZONE_INFO,
  portailId,
  lang,
});

export const setLocaleFromHodor = (locale: string): ISetLocaleFromHodor => ({
  locale,
  type: ApplicationTypeKeys.APPLICATION_SET_LOCALE_FROM_HODOR,
});

export interface ISetD2GQualityAction {
  readonly type: ApplicationTypeKeys.SET_D2G_QUALITY;
  readonly payload: {
    quality;
  };
}

export const setD2GQuality = (quality: SettingQualityD2G): ISetD2GQualityAction => ({
  payload: {
    quality,
  },
  type: ApplicationTypeKeys.SET_D2G_QUALITY,
});

/**
 * setContainerMode
 * set the container mode according the query parameter in the url
 * @param   {boolean} payload
 * @returns {object} action SET_IN_CONTAINER
 */
export interface ISetInContainerMode {
  readonly type: ApplicationTypeKeys.SET_IN_CONTAINER;
  readonly payload: string;
}

export const setInContainerMode = (payload: string): ISetInContainerMode => ({
  type: ApplicationTypeKeys.SET_IN_CONTAINER,
  payload,
});

export interface ISetContextInstantiation {
  readonly platform: Platform;
  readonly type: ApplicationTypeKeys.APPLICATION_SET_CONTEXT_INSTANTIATION;
}

/**
 * Set the context for HODOR calls
 * @returns {object} action APPLICATION_SET_CONTEXT_INSTANTIATION
 */
export const setContextInstantiation = (platform: Platform): ISetContextInstantiation => ({
  platform,
  type: ApplicationTypeKeys.APPLICATION_SET_CONTEXT_INSTANTIATION,
});

export interface ISetPageListAction {
  readonly type: ApplicationTypeKeys.SET_FIRST_LEVEL_PAGE_LIST | ApplicationTypeKeys.SET_HEADER_LEVEL_PAGE_LIST;
  readonly payload: {
    pageList: string[];
  };
}

/**
 * @param {string[]} pageList List of first-level page (this includes gabarit list tabs)
 */
export const setFirstLevelPageList = (pageList: string[]): ISetPageListAction => ({
  type: ApplicationTypeKeys.SET_FIRST_LEVEL_PAGE_LIST,
  payload: { pageList },
});

/**
 * @param {string[]} pageList List of header-level pages
 */
export const setHeaderLevelPageList = (pageList: string[]): ISetPageListAction => ({
  type: ApplicationTypeKeys.SET_HEADER_LEVEL_PAGE_LIST,
  payload: { pageList },
});

export interface IApplicationSetResizeAction {
  readonly payload: { resize: IApplicationState['resize'] };
  readonly type: ApplicationTypeKeys.APPLICATION_SET_RESIZE;
}

export interface ISetWhoIsWatchingUserSettingsAction {
  readonly payload: boolean;
  readonly type:
    | ApplicationTypeKeys.SET_WHO_IS_WATCHING_USER_SESSION
    | ApplicationTypeKeys.SET_WHO_IS_WATCHING_USER_DISABLED;
}

export interface ISetFeatWhoIsWatchingAction {
  readonly payload: boolean;
  readonly type: ApplicationTypeKeys.SET_FEAT_WHO_IS_WATCHING;
}

/**
 * setApplicationResize
 * Setup application resize
 * @param {ResizeValue} resize
 * @returns {object} action IApplicationSetResizeAction
 */
export const setApplicationResize = (resize: ResizeMode): IApplicationSetResizeAction => ({
  payload: { resize },
  type: ApplicationTypeKeys.APPLICATION_SET_RESIZE,
});

export type ApplicationSetHodorSdkConfig = {
  readonly payload: { hodorSdkConfig: HodorSdkConfig };
  readonly type: ApplicationTypeKeys.APPLICATION_SET_HODOR_SDK_CONFIG;
};

// type any configurationjson until we get an update from hodor types
export type ApplicationSetConfiurationJson = {
  readonly payload: { configurationJson: any };
  readonly type: ApplicationTypeKeys.APPLICATION_SET_CONFIGURATION_JSON;
};

export const setApplicationConfigurationJson = (configurationJson: any): ApplicationSetConfiurationJson => ({
  payload: { configurationJson },
  type: ApplicationTypeKeys.APPLICATION_SET_CONFIGURATION_JSON,
});

export const setApplicationHodorSdkConfig = (hodorSdkConfig: HodorSdkConfig): ApplicationSetHodorSdkConfig => ({
  payload: { hodorSdkConfig },
  type: ApplicationTypeKeys.APPLICATION_SET_HODOR_SDK_CONFIG,
});

export const setWhoIsWatchingUserSession = (inSession: boolean): ISetWhoIsWatchingUserSettingsAction => ({
  payload: inSession,
  type: ApplicationTypeKeys.SET_WHO_IS_WATCHING_USER_SESSION,
});

export const setWhoIsWatchingDisabled = (disabled: boolean): ISetWhoIsWatchingUserSettingsAction => ({
  payload: disabled,
  type: ApplicationTypeKeys.SET_WHO_IS_WATCHING_USER_DISABLED,
});

export type ApplicationSetShortVideoIds = {
  readonly payload: { shortVideoIds: string[] };
  readonly type: ApplicationTypeKeys.APPLICATION_SET_SHORT_VIDEO_IDS;
};

export const setApplicationShortVideoIds = (shortVideoIds: string[]): ApplicationSetShortVideoIds => ({
  payload: { shortVideoIds },
  type: ApplicationTypeKeys.APPLICATION_SET_SHORT_VIDEO_IDS,
});

export type ApplicationSetInitTracking = {
  readonly payload: { initTracking: ApiV2InitResponse['tracking'] };
  readonly type: ApplicationTypeKeys.APPLICATION_SET_INIT_TRACKING;
};

export const setApplicationInitTracking = (
  initTracking: ApiV2InitResponse['tracking']
): ApplicationSetInitTracking => ({
  payload: { initTracking },
  type: ApplicationTypeKeys.APPLICATION_SET_INIT_TRACKING,
});

export const setFeatWhoIsWatching = (enabled: boolean): ISetFeatWhoIsWatchingAction => ({
  payload: enabled,
  type: ApplicationTypeKeys.SET_FEAT_WHO_IS_WATCHING,
});

export type ISetSlideshowTrackingAction = {
  readonly payload: Tracking | ApiV2PageTracking | undefined;
  readonly type: ApplicationTypeKeys.APPLICATION_SET_SLIDESHOW_TRACKING;
};
export type ISetSlideshowTrackingContextAction = {
  readonly payload: ApiV2Context | undefined;
  readonly type: ApplicationTypeKeys.APPLICATION_SET_SLIDESHOW_TRACKING_CONTEXT;
};

export const updateSlideshowTracking = (
  tracking: Tracking | ApiV2PageTracking | undefined
): ISetSlideshowTrackingAction => ({
  payload: tracking,
  type: ApplicationTypeKeys.APPLICATION_SET_SLIDESHOW_TRACKING,
});
export const updateSlideshowTrackingContext = (
  context: ApiV2Context | undefined
): ISetSlideshowTrackingContextAction => ({
  payload: context,
  type: ApplicationTypeKeys.APPLICATION_SET_SLIDESHOW_TRACKING_CONTEXT,
});

export type ApplicationActionTypes =
  | ApplicationSetConfiurationJson
  | ApplicationSetHodorSdkConfig
  | ApplicationSetShortVideoIds
  | ApplicationSetInitTracking
  | IApplicationSetResizeAction
  | IGetUserAgentAction
  | IHideFooterAction
  | IHideHeaderAction
  | ILoadD2GSuccessAction
  | ILoadPlayerSuccessAction
  | IOverrideSettingsAction
  | IRenderSourceAction
  | IServiceAuthenticateRequestAction
  | IServiceAuthenticateSuccessAction
  | ISetContextInstantiation
  | ISetD2GQualityAction
  | ISetHostnameAction
  | ISetInContainerMode
  | ISetLocaleFromHodor
  | ISetPageListAction
  | ISetWhoIsWatchingUserSettingsAction
  | ISetFeatWhoIsWatchingAction
  | ISetZoneInfoAction
  | IShowGDPRAction
  | ISetSlideshowTrackingAction
  | ISetSlideshowTrackingContextAction;
