import { PassClientIds } from '@canalplus/mycanal-commons';
import { FetchOptions } from '@canalplus/mycanal-fetch';
import { LogConfig } from '@canalplus/mycanal-logger';
import { Endpoint } from '@canalplus/mycanal-sdk';
import { BusinessTypes } from '@canalplus/oneplayer-constants';
import { IMappingConfig, TEnv } from '@canalplus/oneplayer-types';
import { AcmSdkConfig } from '@canalplus/sdk-acm';
import { Environment, HodorPlatform, Lang, OfferLocation, Operator, Platform, Zone } from '@canalplus/sdk-core';
import { HodorSdkConfig, InitParameters } from '@canalplus/sdk-hodor';
import type { PassSdkConfig } from '@canalplus/sdk-pass';

// ⚠️ Do not import from source since it may cause deployment issues
type HapiXxService = 'mycanal' | 'cplay';

interface ICache {
  page_cache: boolean;
  expiration_time: string;
  application_tree: string;
  ws_from_path: string;
  format: string;
}

interface IPass {
  applicationKey: string;
  clientIds: PassClientIds;
  endpoint?: Endpoint & { accountPublicApi: string };
  isPrivate?: boolean;
  operator: Operator;
  passDistributorId: string;
  portailId: number;
  portailIdEncrypted: string;
  timeout: number;
}

interface ITimeoutPaymentProvider {
  default: number;
  processPayment: number;
  funnelContent: number;
}

interface IPaymentProvider {
  base_url: string;
  bom_base_url: string;
  path_paymentmeans: string;
  path_process_payment: string;
  path_delete_credit_card: string;
  path_redirect_payment: string;
  path_getAddresses: string;
  path_getDeleveryAddresses: string;
  addressValidOnly: boolean;
  user_id: string;
  timeout: ITimeoutPaymentProvider;
  distributor_id: string;
  sale_device: string;
}

type Platforms = {
  [T in Exclude<Platform, 'OrangeKey'>]: {
    arboVersion: HodorSdkConfig['arboVersion'];
    configVersion: string;
    device: HodorPlatform;
  };
};

type ConfigHodor = {
  abTestingPercentage: number;
  defaultAppKey: AppKey;
  baseUrl: HodorSdkConfig['baseUrl'];
  paths: HodorSdkConfig['paths'] & {
    avatar: `/{media}/{portailId}/${string}`;
    getFavoritesLiveGrid: `/${string}/{cmsToken}/${string}`;
    list: `/${string}/{cmsToken}/${string}/{listType}/${string}`;
    privacy: `/${string}/{cmsToken}/${string}`;
    profile: `/${string}/{media}/{portailId}/${string}`;
    profileUrl: string;
    saveFavoritesLiveGrid: `/${string}/{cmsToken}/${string}`;
    termsOfSale: `/${string}/{cmsToken}/${string}`;
  };
  platforms: Platforms;
  fetchOptions: FetchOptions;
  initParameters: Required<Pick<InitParameters, 'allowedProfiles'>> & Omit<InitParameters, 'allowedProfiles'>;
  profileParameters: {
    profilesTemplateVersion: string;
  };
};

type ConfigPass = {
  baseUrl: PassSdkConfig['baseUrl'];
  clientIds: PassClientIds;
  fetchOptions: FetchOptions;
};

interface IApi {
  pass: IPass;
  sdkPass: ConfigPass;
  update_user_profile: { idClient: string };
  hodor: ConfigHodor;
  acm: { endpoint: AcmSdkConfig['endpoint'] };
  paymentPageBaseUrl: string;
  paymentProvider: IPaymentProvider;
  sale_status: { path: string };
  hapi: { base_url: string; xx_service: HapiXxService; timeout: number; path_pulling_hapi_redirect_payment: string };
  address: { url: string };
}

interface IMeta {
  titleTemplate: string;
  twitter: { twitterAccount: string; urlImageDefault: string };
  meta: Array<{ name?: string; content?: string; charset?: string; property?: string }>;
  link: Array<ILink>;
  defaultHostname: string;
}

interface ILink {
  rel?: string;
  href?: string;
  sizes?: string;
  media?: string;
}

export interface IMetas {
  app: {
    title: string;
    description: string;
    head: IMeta;
  };
}

interface IMonitoring {
  title: string;
  head: {
    titleTemplate: string;
    meta: Array<{ name?: string; content?: string; charset?: string }>;
  };
}

export interface IDocumentHeader {
  [OfferLocation.pl]: IMetas;
  [OfferLocation.en]: IMetas;
  [OfferLocation.it]: IMetas;
  default: IMetas;
  monitoring: IMonitoring;
}

interface IDefaultLocale {
  offerLocation: OfferLocation;
  langKey: Lang;
}

interface IPlayer {
  embededUrl: string;
  ONE_PLAYER: {
    BASE_URLS: {
      default: string;
      [Platform.LG]: string;
      [Platform.PlayStation4]: string;
    };
    BUNDLE_NAME: string;
    CONFIG_BASE_URL?: string;
    CONTEXT: keyof IMappingConfig;
    MINIMAL_BUNDLE_NAME: string;
    ONE_PLAYER_ENV: TEnv;
    VARIANT: `${BusinessTypes.VARIANTS}`;
  };
  PLATFORM: {
    LIVE: string;
    VOD: string;
    ATG: string;
    HAPI: string;
    PFV: string;
    DOWNLOADTOGO: string;
  };
  DOWNLOAD_TO_GO: {
    BASE_URL: string;
    BUNDLE_NAME: string;
  };
}

interface ITemplate {
  AUTHENTICATION: string;
  BANNER: string;
  CARROUSEL: string;
  CONTENT_GRID: string;
  CONTENT_ROW: string;
  CONTEXTUAL_OFFER: string;
  CREATIVE_MEDIA: string;
  DELETE_ACCOUNT: 'deleteAccount';
  DETAIL_LIGHT: string;
  DETAIL_PAGE: string;
  DETAIL_SEASON: string;
  DETAIL_SHOW: string;
  DOWNLOAD_MANAGER: string;
  DOWNLOAD_TO_GO: string;
  ERROR: string;
  EXTERNAL_SITE: string;
  FULLSCREEN: string;
  FUNNEL: string;
  GABARIT_LIST: string;
  LANDING: string;
  LIVE_GRID: string;
  LOGOUT: string;
  MODE: {
    LIVE_TV: string;
  };
  MORE_INFOS: string;
  MOSAIC: string;
  MY_ACCOUNT_IDP: string;
  MY_ACCOUNT: string;
  PARENTAL_CODE: string;
  PAYMENT_MEANS: string;
  PLAIN_TEXT_HTML: string;
  PRIVACY_MANAGER: string;
  PURCHASE_CODE_DESACTIVATION: string;
  PURCHASE_CODE: string;
  QUICKTIME: string;
  REGISTER: string;
  SEARCH: string;
  SECTIONS_LIST: string;
  SELECT_REGION: string;
  SETTINGS: string;
  SHOWCASE: string;
  SLIDESHOW: string;
  STUB: string;
  TEXT_BRUT: string;
  TV_GUIDE: string;
  UPDATE_RIGHTS: string;
  VIOLENT_CONTENT: string;
}

interface IReviews {
  TYPE: {
    ALLOCINE: string;
  };
}

export enum TrackingEnvironment {
  Prod = 'prod',
  Staging = 'staging',
}

type EnvironmentConfig = {
  'beta.': TrackingEnvironment.Staging;
  'default': TrackingEnvironment.Prod;
  'dev.': TrackingEnvironment.Staging;
  'int.': TrackingEnvironment.Staging;
  'local.': TrackingEnvironment.Staging;
  'pprod.': TrackingEnvironment.Staging;
  'rec.': TrackingEnvironment.Staging;
  'review-app-': TrackingEnvironment.Staging;
};

interface IOmniture {
  OMNITURE_REPORTSUITE: string | null;
  BUNDLE: string;
  REPORTSUITE: null;
  DTM_KEY: string;
  TEALIUM_KEY: string;
  CHANNEL: string;
  FILTER: string;
  ENVIRONMENT: EnvironmentConfig;
}

interface ITrackingConfig {
  PASS_CONF: {
    DEFAULT_CONF: {
      msg_act_ok_gene: string;
      pwdreinit_gene_gene: string;
      pwdmod_gene_gene: string;
    };
  };
}

interface ITracking {
  OMNITURE: IOmniture;
  CONFIG: ITrackingConfig;
}

interface IFooter {
  socialLinks: {
    facebook: string;
    twitter: string | null;
    dailymotion: string | null;
    youtube: string | null;
  };
}

interface ILog {
  client: LogConfig;
  server: LogConfig;
}

type Debug = {
  externalReactDevTools: boolean;
  reactQueryDevtools: boolean;
};

type App = {
  account: {
    base_url: `https://${string}`;
    integration_base_url: `https://${string}.int.${string}`;
  };
};

export enum Overlay {
  MyCanal = 'mycanal',
  TIM = 'tim',
  TVODStandalone = 'tvodstandalone',
}

const AppKeys = ['mycanal', 'mycanalint', 'timvision', 'canalvod', 'mycanaleth', 'mycanalchde'] as const;

export type AppKey = (typeof AppKeys)[number];

export const isAppKey = (appKey: string): appKey is AppKey => AppKeys.includes(appKey as AppKey);

export interface IPublic {
  mock: boolean;
  documentHeader: IDocumentHeader;
  cache: ICache;
  cookie: {
    /**
     * maxAge in days
     */
    maxAge: number;
  };
  environment: Environment;
  app: App;
  api: IApi;
  defaultLocale: IDefaultLocale;
  zones: Zone;
  PLAYER: IPlayer;
  TEMPLATE: ITemplate;
  MICRO_ELIGIBILITY_FILTERS: {
    LIVE: Array<string>;
    VOD: Array<string>;
  };
  REVIEWS: IReviews;
  TRACKING: ITracking;
  DIDOMI?: { ACTIVE?: boolean; API_PUBLIC_KEY?: string };
  DOWNLOADTOGO: { BUNDLE: string; HOST: string; CUSTOM_PROTOCOL_URL: string; ALLOWED_OFFER_LOCATION: OfferLocation[] };
  DOWNLOAD_TO_GO?: { BASE_URL: '' };
  CGV: { link: string };
  CANALVOD: { link: string };
  footer: IFooter;
  search_results: {
    personality: string;
  };
  offline: {
    url: string;
  };
  user_status: {
    prospect: 'prospect';
    abonne: 'abonne';
  };
  log: ILog;
  debug: Debug;
  overlay: Overlay;
  systemPaySdk: string;
  appSmartBanners: {
    iOSAppID: string;
  };
  newrelic: {
    path: {
      [offerLocation: string]: string;
    };
  };
  proxificationList?: ProxificationList;
}

interface IApiServer {
  pass: {
    clientSecrets: PassClientIds;
  };
}

interface IRewrite {
  [key: string]: {
    [key: string]: string;
  };
}

export type RewriteRule = {
  match: {
    request: {
      path: `/${string}`;
      query?: Record<string, string>;
    };
    overlay?: Overlay;
    offerLocation?: OfferLocation;
  };
  to: `/${string}`;
};

interface IServer {
  port: number;
  keepAliveTimeout: number;
  headersTimeout: number;
  ssr: boolean;
  api: IApiServer;
  multithread: boolean;
  timeout: number;
  cookie: {
    /**
     * max_age in days
     */
    maxAge: number;
  };
  proxyUrl?: string;
  rewrites: IRewrite;
  rewriteRules: RewriteRule[];
  frameAncestors: string[];
}

interface ITests {
  unit: {
    server: {
      port: number;
    };
  };
  load: {
    max: number;
    mean: number;
  };
}
type ProxiItem = { from: string; to: string };

export type ProxificationList = ProxiItem[];

export interface IConfig {
  public: IPublic;
  server: IServer;
  apm: { region: string; tag: string; label: string };
  cache: { assets: { max_age: number; always_refresh: boolean } };
  assets_extensions: Array<string>;
  log: { store: boolean };
  tests?: ITests;
  proxificationList?: ProxificationList;
}
