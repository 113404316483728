import { addQueryParam } from '@canalplus/mycanal-commons';
import { IContent } from '@canalplus/mycanal-sdk';
import { ApiV2ChannelFavoriteSelection } from '@dce-front/hodor-types/api/v2/channels/favorite_channels/definitions';
import { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import type {
  ApiV2BroadcastChannel,
  ApiV2BroadcastContent,
} from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/live_grid/definitions';
import flatMap from 'lodash/flatMap';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  type LiveContentStatus,
  LIVE_CONTENT_STATUS_COMPLETED,
  LIVE_CONTENT_STATUS_LIVE,
  LIVE_CONTENT_STATUS_UPCOMING,
  LIVE_GRID_CHANNELS_TO_FETCH,
} from '../../constants/liveGrid';
import {
  LIVE_FILTER_ATTEMPT_REFETCH_INTERVAL,
  LIVE_FILTER_DEFAULT_FILTER_TIME,
  LIVE_FILTER_MIN_REFETCH_WAIT_TIME,
} from '../../constants/liveTv';
import { MULTI_LIVE_MAX_STREAMS, MULTI_LIVE_MIN_STREAMS } from '../../constants/multiLive';
import { PlayerPlatform } from '../../constants/playerPlatforms';
import type { AccessibleChannel } from '../../store/slices/user-type';
import { isAfter, isBefore, isWithinInterval } from '../date/date-helper';

/**
 * Get the percentage of progress for currently live content
 *
 * @param startTime
 * @param endTime
 * @example
 * // returns 50 when Date.now() is 1500
 * getLiveContentProgressPercentage(1000, 2000)
 */
export const getLiveContentProgressPercentage = (startTime?: number, endTime?: number): number | null => {
  if (!startTime || !endTime) return null;

  const now = Date.now();
  const isContentLive = isWithinInterval(now, { start: startTime, end: endTime });

  return isContentLive ? Math.round(100 * ((now - startTime) / (endTime - startTime))) : null;
};

export const getLiveContentStatus = (content: ApiV2BroadcastContent, now = new Date().getTime()): LiveContentStatus => {
  const { startTime = 0, endTime = 0 } = content;
  if (isBefore(endTime, now)) {
    return LIVE_CONTENT_STATUS_COMPLETED;
  } else if (isWithinInterval(now, { start: startTime, end: endTime })) {
    return LIVE_CONTENT_STATUS_LIVE;
  } else if (isAfter(startTime, now)) {
    return LIVE_CONTENT_STATUS_UPCOMING;
  }
  throw new Error('getLiveContentStatus :: start time can not be after end time');
};

/**
 * Returns true if the content is currently live
 *
 * @param {ApiV2BroadcastContent}  content
 * @param {number}        now
 * @returns {boolean}
 */
export const isLiveContentCurrentlyLive = (content: ApiV2BroadcastContent, now = new Date().getTime()): boolean =>
  getLiveContentStatus(content, now) === LIVE_CONTENT_STATUS_LIVE;

/**
 * Returns true if the content starts in the future
 *
 * @param {ApiV2BroadcastContent}  content
 * @param {number}        now
 * @returns {boolean}
 */
export const isLiveContentUpcoming = (content: ApiV2BroadcastContent, now = new Date().getTime()): boolean =>
  getLiveContentStatus(content, now) === LIVE_CONTENT_STATUS_UPCOMING;

export interface ILiveGridPlayerDataOnClick {
  trackingContext?: ApiV2Context;
}

export interface ILiveGridPlayerData {
  channel: {
    epgID: number;
  };
  onClick: ILiveGridPlayerDataOnClick;
  startLiveProgramFromBeginning: true | undefined;
}

/**
 * This helper is used to create an object that is compatible with the object expected by
 * launchPlayerFullScreen and subsequent actions/helpers
 *
 * @param {object}    channel
 * @param {object}    context
 * @param {boolean}   shouldStartOver
 * @returns {object}  LiveGrid content formatted for Player
 */

export const formatLiveGridPlayerData = (
  channel: ApiV2BroadcastChannel | IContent,
  context?: ApiV2Context,
  shouldStartOver: boolean = false
): ILiveGridPlayerData => ({
  channel: {
    epgID: Number(channel.epgID), // Used in launchPlayerFullScreen > getContentID helper in fullscreen-helper.ts
  },
  onClick: {
    trackingContext: context, // Used in launchPlayerFullScreen & openFullscreen actions
  },
  startLiveProgramFromBeginning: !!shouldStartOver || undefined,
});

export type FormatMultiLivePlayerData = {
  content: string;
  platform: PlayerPlatform;
}[];
/**
 * Like the above helper, this helper is used to create an object that is compatible with the object expected by
 * launchPlayerFullScreen and subsequent actions/helpers
 *
 * @param {number[]}  epgIDs
 * @returns {object}
 */
export const formatMultiLivePlayerData = (epgIDs: number[]): FormatMultiLivePlayerData =>
  epgIDs.map((epgID) => ({ content: epgID.toString(), platform: PlayerPlatform.Live }));

/**
 * Get a list of epgIDs from the accessibleChannels object
 *
 * @param {array of objects}    channels
 * @returns {array or numbers}  all the epgIDs
 */
export const extractEpgIDsFromAccessibleChannels = (channels: AccessibleChannel[] = []): number[] =>
  channels.map((channel: AccessibleChannel) => channel.epgID);

/**
 * Add query string to live url
 *
 * @param {string}              url
 * @param {array of numbers}    epgIDs
 * @returns {string}            formatted url
 */
export const getFormattedLiveUrl = (url: string, epgIDs: number[], nbResultsRequested: number): string => {
  let formattedUrl = url;

  // Add "epgIds" param to limit which channels Hodor returns
  if (epgIDs.length) {
    formattedUrl = addQueryParam(formattedUrl, 'epgIds', String(epgIDs));
  }

  // Add "get" param to set the maximum number of results we want Hodor to return
  formattedUrl = addQueryParam(formattedUrl, 'get', String(nbResultsRequested));

  return formattedUrl;
};

export const formatLiveGridUrl = (
  url: string,
  epgIDs: number[] = [],
  nbResultsRequested = LIVE_GRID_CHANNELS_TO_FETCH
): string => getFormattedLiveUrl(url, epgIDs, nbResultsRequested);

export const toggleFavoriteChannelByEpgID =
  (epgIDtoToggle: number) =>
  (channel: ApiV2ChannelFavoriteSelection): ApiV2ChannelFavoriteSelection => {
    if (channel.epgID === epgIDtoToggle) {
      return { ...channel, isFavorite: !channel.isFavorite };
    }
    return channel;
  };
interface IChangedFavoriteEpgIDsState {
  epgIDsToAdd: number[];
  epgIDsToRemove: number[];
}

export const formatLiveGridFavoritesUrl = (url: string, epgIDs: number[] = []): string =>
  addQueryParam(url, 'epgids', String(epgIDs));

// Used on the Edit Favorites component to determine which channels have been selected/deselected by the user
export const getChangedFavoriteEpgIDs = (
  newChannels: ApiV2ChannelFavoriteSelection[],
  originalChannels: ApiV2ChannelFavoriteSelection[]
): IChangedFavoriteEpgIDsState =>
  newChannels.reduce(
    (result, channel, index) => {
      if (channel?.isFavorite !== originalChannels[index].isFavorite) {
        if (channel?.isFavorite) {
          result.epgIDsToAdd.push(channel.epgID);
        } else {
          result.epgIDsToRemove.push(channel.epgID);
        }
      }
      return result;
    },
    { epgIDsToAdd: [] as number[], epgIDsToRemove: [] as number[] }
  );

/* -------------------
    MULTI-LIVE SETUP
---------------------- */

/**
 * MultiLive has limitations regarding how many channels it can launch at once.
 * This helper takes the amount of channels the user has selected and determines
 * if it's compatible with launching the Player in multiLive mode.
 *
 * @param {number}  numberOfChannelsSelected
 * @returns {boolean}
 */
export const isMultiLiveSetupLaunchable = (numberOfChannelsSelected: number): boolean =>
  numberOfChannelsSelected >= MULTI_LIVE_MIN_STREAMS && numberOfChannelsSelected <= MULTI_LIVE_MAX_STREAMS;

/**
 * Adds or removes an epgID from the list of previously selected epgIDs
 *
 * @param {number[]}   selectedEpgIDs
 * @param {number}     epgID
 * @param {boolean}    isSelectionFull
 * @returns {number[]} The new list of selected epgIDs
 */
export const toggleMultiLiveChannelSelectionByEpgID = (
  selectedEpgIDs: number[],
  epgID: number, // channel to toggle
  isSelectionFull: boolean
): number[] => {
  const index = selectedEpgIDs.indexOf(epgID);
  const newSelection = [...selectedEpgIDs];
  if (index !== -1) {
    newSelection.splice(index, 1); // Remove it
  } else if (!isSelectionFull) {
    newSelection.push(epgID); // Add it
  }
  return newSelection;
};

/**
 * Returns the epgID's selection index + 1, or null if the epgID is not in the list of selections
 *
 * @param {number[]}   selectedEpgIDs
 * @param {number}     epgID
 * @returns {number | null}
 */
export const getMultiLiveSelectionNumber = (selectedEpgIDs: number[], epgID: number): number | null => {
  const index = selectedEpgIDs.indexOf(epgID);
  if (index === -1) {
    return null; // epgID is not selected
  } else {
    return index + 1; // Convert zero-based index to one-based index
  }
};

/**
 * Returns an object where epgIDs are the keys, and the value is a boolean indicating if the associated card is disabled.
 *
 * @param {number[]}   allEpgIDs
 * @param {number[]}   selectedEpgIDs
 * @param {boolean}    isSelectionFull
 * @returns {object}
 */
export const createMultiLiveCardDisabledMapping = (
  allEpgIDs: number[],
  selectedEpgIDs: number[],
  isSelectionFull: boolean
): { [key: number]: boolean } =>
  allEpgIDs.reduce(
    (mapping, epgID) => {
      mapping[epgID] = isSelectionFull && !selectedEpgIDs.includes(epgID);
      return mapping;
    },
    {} as { [key: number]: boolean }
  );

/**
 * Converts a live content to an array containing start and end times
 *
 * @param {ApiV2BroadcastContent}   content
 * @returns {(number | undefined)[]}
 */
export const getLiveContentStartEndTimes = (content: ApiV2BroadcastContent): (number | undefined)[] => [
  content?.startTime,
  content?.endTime,
];

/**
 * Converts a live channel into a flat array of all the channel's contents' start and end times
 *
 * @param {ApiV2BroadcastChannel}   channel
 * @returns {(number | undefined)[]}
 */
export const getLiveChannelStartEndTimes = (channel: ApiV2BroadcastChannel): (number | undefined)[] =>
  flatMap(channel.contents, getLiveContentStartEndTimes);

/**
 * Converts a list of live channels into a flat array of all the channels' contents' start and end times.
 * The list is sorted chronologically, and has no duplicates.
 *
 * @param {ApiV2BroadcastChannel[]}   channels
 * @returns {(number | undefined)[]}
 */
export const getLiveChannelsPotentialFilterPoints = (channels: ApiV2BroadcastChannel[]): (number | undefined)[] =>
  [...new Set(flatMap(channels, getLiveChannelStartEndTimes))].sort();

/**
 * Takes a list of (sorted) timestamps and returns the first one that is in the future, or null if none exist.
 *
 * @param {(number | undefined)[]}   timeStamps
 * @returns {number | null}
 */
export const getFirstFutureTimestamp = (timeStamps: (number | undefined)[]): number | null => {
  const now = Date.now();
  return timeStamps.find((time = 0) => time > now) || null;
};

/**
 * Takes a list of live channels and return an object containing:
 * - currentlyLiveChannels: a list of live channels whose "contents" array is either empty or contains one currently live content
 * - someChannelsLackRelevantContent: true if any channel lacks both currently-live and future contents (used to determine when a refetch is needed)
 *
 * @param channels
 * @param includeContentlessChannels: when true, the returned filtered list will include channels for which we have no currently-live content
 * @returns {object}
 */
export const filterLiveChannels = (
  channels: ApiV2BroadcastChannel[],
  includeContentlessChannels: boolean = false
): { currentlyLiveChannels: ApiV2BroadcastChannel[]; someChannelsLackRelevantContent: boolean } => {
  let someChannelsLackRelevantContent = !channels.length; // if the list is empty, I think it's safe to say it lacks relevant content
  const now = new Date().getTime();

  const currentlyLiveChannels = channels.reduce((acc, channel) => {
    const filteredChannel: ApiV2BroadcastChannel = { ...channel, contents: [] };
    const currentlyLiveContent = channel.contents?.find((content) => isLiveContentCurrentlyLive(content, now));
    if (currentlyLiveContent) {
      filteredChannel.contents = [currentlyLiveContent];
    } else {
      const hasUpcomingContent = !!channel.contents?.find((content) => isLiveContentUpcoming(content, now));
      if (!hasUpcomingContent) {
        someChannelsLackRelevantContent = true;
      }
    }
    if (currentlyLiveContent || includeContentlessChannels) {
      acc.push(filteredChannel);
    }
    return acc;
  }, [] as ApiV2BroadcastChannel[]);

  return { currentlyLiveChannels, someChannelsLackRelevantContent };
};

/**
 * CUSTOM REACT HOOK
 *
 * Feed it a raw live channel list from hodor, and a callback that will refetch the channels.
 * You will be rewarded with a list of channels, each with either one live content or no contents at all.  The list regularly updates itself.
 *
 * It determines when contents start and end, and based on that it re-filters the channels when needed.
 * This ensures the UI always displays what is currently-live.
 * It also determines when the channels need to be re-fetched (when it finds channels that lack currently-live and future contents)
 *
 * @param channels: list of live channels, each with a list of contents
 * @param refetchChannels: callback to be executed when contents need to be re-fetched
 * @param includeContentlessChannels: when true, the returned filtered list will include channels for which we have no currently-live content
 * @returns {ApiV2BroadcastChannel[]}
 */
export const useLiveChannelsFilter = (
  channels: ApiV2BroadcastChannel[],
  refetchChannels: () => void,
  includeContentlessChannels?: boolean
): ApiV2BroadcastChannel[] => {
  const lastFetchTimestamp = useMemo(() => Date.now(), [channels]); // eslint-disable-line react-hooks/exhaustive-deps

  const potentialFilterPoints = useMemo(() => getLiveChannelsPotentialFilterPoints(channels), [channels]);
  const [filteredChannels, setFilteredChannels] = useState<ApiV2BroadcastChannel[]>([]);
  const [isRefetchRequested, setIsRefetchRequested] = useState<boolean>(false);

  const potentiallyRefetch = useCallback(() => {
    // Refetch channels if it's been requested and enough time has elapsed since the last fetch
    if (isRefetchRequested && Date.now() - lastFetchTimestamp > LIVE_FILTER_MIN_REFETCH_WAIT_TIME) {
      refetchChannels();
    }
  }, [isRefetchRequested, lastFetchTimestamp, refetchChannels]);

  // When a re-fetch is requested, try to re-fetch every so often
  useEffect(() => {
    let intervalID;
    if (isRefetchRequested) {
      potentiallyRefetch();
      intervalID = setInterval(potentiallyRefetch, LIVE_FILTER_ATTEMPT_REFETCH_INTERVAL);
    }
    return () => {
      clearInterval(intervalID);
    };
  }, [isRefetchRequested, potentiallyRefetch]);

  const filterChannels = useCallback(() => {
    const { currentlyLiveChannels, someChannelsLackRelevantContent } = filterLiveChannels(
      channels,
      includeContentlessChannels
    );
    setFilteredChannels(currentlyLiveChannels);

    if (someChannelsLackRelevantContent) {
      if (!isRefetchRequested) {
        setIsRefetchRequested(true);
      }
    } else if (isRefetchRequested) {
      // This block will usually run after newly refetched channels get filtered for the first time
      setIsRefetchRequested(false);
    }
  }, [channels, includeContentlessChannels, isRefetchRequested]);

  // Filter channels on mount and whenever newly fetched channels arrive
  useEffect(() => {
    filterChannels();
  }, [channels]); // eslint-disable-line react-hooks/exhaustive-deps

  // Always have a timer going to refilter the content
  useEffect(() => {
    const nextFilterTimestamp =
      getFirstFutureTimestamp(potentialFilterPoints) || Date.now() + LIVE_FILTER_DEFAULT_FILTER_TIME;
    const timeToNextFilter = nextFilterTimestamp - Date.now() + 100; // Add 100ms so we perform the filter just after a content ends
    const nextFilterTimeoutID = setTimeout(filterChannels, timeToNextFilter);

    return () => {
      clearTimeout(nextFilterTimeoutID);
    };
  });

  return filteredChannels;
};
