/**
 * Add a header to an existing HeadersInit object. Returns the new HeadersInit
 *
 * @param headers Headers init object
 * @param name Header name
 * @param value Header value
 */
export function addHeader(headers: HeadersInit | undefined, name: string, value: string): HeadersInit {
  if (Array.isArray(headers)) {
    return [...headers, [name, value]];
  } else if (headers instanceof Headers) {
    const updatedHeaders = new Headers(headers);
    updatedHeaders.set(name, value);

    return updatedHeaders;
  } else if (headers) {
    return { ...headers, [name]: value };
  }

  return { [name]: value };
}
