import { hasUserDataCollectedSelector, profilesUpdateDateSelector } from '../../../../store/slices/user-selectors';
import type { IState } from '../../../../store/types/State-type';
import { getAllProfiles } from '../../../profiles/profileStorageService';

export const shouldStoreProfiles = (state: IState): boolean => {
  const hasUserDataCollected = hasUserDataCollectedSelector(state);
  const profilesUpdateDate = profilesUpdateDateSelector(state);

  const { currentProfile, profiles, profilesUpdateDate: profilesUpdateDateStored } = getAllProfiles();

  return hasUserDataCollected && (profilesUpdateDateStored !== profilesUpdateDate || !profiles || !currentProfile);
};
