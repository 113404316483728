/* eslint-disable global-require */
import { Lang, OfferLocation, OfferZone, getZoneParameters, isOfferLocation } from '@canalplus/sdk-core';
import type { Request } from 'express';
import { AppKey, IConfig, IPublic } from '../../../config/application/types';

/** Return the whole public configuration */
export const getPublicConfig = (): IPublic => {
  // On server-side, retrieving the client config from the node config
  if (IS_SERVER) {
    // Conditional require is mandatory since only node server can handle it
    return require('config').get('public');
  }

  // On client-side, the config is already loaded in window
  return (window as any).app_config;
};

/** Return the whole server configuration */
export const getServerConfig = (): IConfig => {
  if (IS_SERVER) {
    // Conditional require is mandatory since only node server can handle it
    return require('config');
  }

  throw new Error('Server config is only available in server execution context.');
};

type Locale = {
  appKey?: AppKey;
  lang?: Lang;
  offerLocation: OfferLocation;
  offerZone: OfferZone;
  portailId?: string;
};

/**
 * Get the locale data from the path and the query
 * `isTvDevice`: boolean
 * `path`: string
 * `offerLocationQuery: string | null
 * @returns Locale
 */
export const getLocaleData = (
  isTvDevice: boolean,
  path: string,
  offerLocationQuery?: Request['query'] | string | null,
  offerZoneQuery?: Request['query'] | string | null
): Locale => {
  const {
    api: {
      pass: { operator },
    },
    defaultLocale,
    zones,
  } = getPublicConfig();

  const [, splittedOfferLocation] = path.split('/');

  const offerLocationFromPath = isOfferLocation(splittedOfferLocation)
    ? splittedOfferLocation
    : defaultLocale.offerLocation;

  const offerLocationTv =
    offerLocationQuery && isOfferLocation(offerLocationQuery) ? offerLocationQuery : defaultLocale.offerLocation;

  const { offerLocation, lang, portailId, offerZone, appKey } = getZoneParameters({
    offerLocation: isTvDevice ? offerLocationTv : offerLocationFromPath,
    // Temporary dev for german-speaking switzerland (waiting for the multi-language)
    lang: path.includes('/ch/de') || offerZoneQuery === OfferZone.cpchd ? 'de' : defaultLocale.langKey,
    zones,
    operator,
  });

  return { offerLocation, lang, portailId, offerZone, appKey };
};
