import { requestPage } from '@canalplus/sdk-hodor';
import { ApiV2GetMediaUrlPage } from '@dce-front/hodor-types';
import { DataLayerTracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import { DeeplinkDetailInformation } from '@dce-front/hodor-types/api/v2/me/get_media_url/definitions';
import { ApiV2PageTrackingDataLayer } from '@dce-front/hodor-types/api/v2/page/dtos/definitions';
import { R7NavigateDataSkey } from 'r7extlib';
import { Middleware } from 'redux';
import { apiCallWithTokenpassRenewal } from '../../api/PersoApi/PersoApi';
import type { ApiCallOptionParameters } from '../../api/types';
import { DEEPLINK, PLAY_DEEPLINK, START_DOWNLOAD_DEEPLINK } from '../../constants/tracking';
import { getPublicConfig } from '../../helpers/config/config-helper';
import Logger from '../../helpers/logger/logger-helper';
import {
  type SendDeeplinkTrackingEventOptions,
  sendDeeplinkTrackingEvent,
} from '../../helpers/tracking/tracking-helper';
import { getUrlWithTokenCMS } from '../../helpers/url/url-helper';
import { isR7Available } from '../../helpers/window/window-helper';
import {
  getFeatureExternalServicesSelector,
  hodorSdkConfigSelector,
  offerLocationSelector,
  tokenCMSSelector,
} from '../../selectors/application/application-selectors';
import type { AllActionsTypes } from '../reducers/actions/All-actions-types';
import {
  ActionExternalServicesTypeKeys,
  type IHandleExternalServiceTrackingAction,
  type ILaunchExternalServiceAction,
} from '../reducers/actions/ExternalServices-actions';
import { displayTVModeSelector } from '../slices/displayMode-selectors';
import { pageURLSelector } from '../slices/page-selectors';
import { effectiveTrackingContextSelector, findTrackingSelector } from '../slices/tracking-selectors';
import { abTestingPopulationSelector, isKidsProfileSelector, segTypeSelector } from '../slices/user-selectors';
import type { IState } from '../types/State-type';

const { LAUNCH_EXTERNAL_SERVICE, HANDLE_EXTERNAL_SERVICE_TRACKING } = ActionExternalServicesTypeKeys;

/**
 * Launch external service web from detail URLMedias
 * @param detail
 * @param dataTracking
 */
const launchExternalServiceWeb = (
  detail: ApiV2GetMediaUrlPage['detail'] | undefined,
  dataTracking: SendDeeplinkTrackingEventOptions
) => {
  // Get external service url from URLMedias response
  const externalUrl = (detail?.informations as DeeplinkDetailInformation)?.deeplink?.data?.url;

  if (externalUrl) {
    sendDeeplinkTrackingEvent(dataTracking);
    window.open(externalUrl, '_blank');
  } else {
    Logger.error(
      `ExternalService::launchExternalService() - Error occurs during launchExternalService. No externalUrl found`
    );
  }
};

/**
 * Launch external service tv from detail URLMedias
 * @param detail
 * @param dataTracking
 */
const launchExternalServiceTv = (
  detail: ApiV2GetMediaUrlPage['detail'] | undefined,
  dataTracking: SendDeeplinkTrackingEventOptions
) => {
  if (isR7Available()) {
    const deepLinkParams =
      detail && 'deeplink' in detail.informations ? detail.informations.deeplink?.data?.params : undefined;

    const serviceID = deepLinkParams
      ? deepLinkParams.find((item) => item.field?.toLowerCase() === 'serviceid')?.value
      : undefined;

    if (serviceID) {
      sendDeeplinkTrackingEvent(dataTracking);

      const contentID = deepLinkParams
        ? deepLinkParams.find((item) => item.field?.toLowerCase() === 'contentid')?.value
        : undefined;

      window.R7('navigate', {
        skey: serviceID,
        context: contentID ? { deeplink: { contentId: contentID } } : undefined,
      } as R7NavigateDataSkey);
    } else {
      Logger.error(
        `ExternalService::launchExternalService() - Error occurs during launchExternalService. No serviceID found.`
      );
    }
  } else {
    Logger.error(
      `ExternalService::launchExternalService() - Error occurs during launchExternalService. Api R7 is not available.`
    );
  }
};

export const externalServicesMiddleware: Middleware<object, IState, Redux.Dispatch> =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    const state = getState();

    switch ((action as AllActionsTypes).type) {
      case LAUNCH_EXTERNAL_SERVICE:
        try {
          const featExternalServices = getFeatureExternalServicesSelector(state);
          const tokenCMS = tokenCMSSelector(state);

          if (!featExternalServices || !tokenCMS) {
            break;
          }

          const { URLMedias, isDownload } = (action as ILaunchExternalServiceAction).payload;

          // Call URLMedias to get external service url (stream or download)
          const urlWithTokenCMS = getUrlWithTokenCMS(URLMedias, tokenCMS);

          const apiCall = (options: ApiCallOptionParameters) => {
            const newOptions = {
              headers: {
                ...options.headers,
                passtoken: options.headers.tokenPass,
              },
              timeout: getPublicConfig().api.paymentProvider.timeout.default,
            };
            // Delete unauthorized headers to avoid potential cors errors
            delete (newOptions as any).headers.passtoken;
            return fetch(urlWithTokenCMS, newOptions);
          };
          const response = await dispatch(apiCallWithTokenpassRenewal(apiCall as any));

          if (!response) {
            throw new Error('launchExternalService failed to fetch');
          }

          const json: ApiV2GetMediaUrlPage = await response.json();
          const { detail, tracking } = json || {};

          const dataLayer = tracking?.dataLayer || {};
          const context = effectiveTrackingContextSelector(state);
          const trackingWithContext = { ...dataLayer, ...context };
          const trackingName = isDownload ? START_DOWNLOAD_DEEPLINK : PLAY_DEEPLINK;
          const abTestingPopulation = abTestingPopulationSelector(state);
          const segType = segTypeSelector(state);
          const pageURL = pageURLSelector(state);
          const offerLocation = offerLocationSelector(state);
          const isKids = isKidsProfileSelector(state);
          const isTvDevice = displayTVModeSelector(state);

          const dataTracking: SendDeeplinkTrackingEventOptions = {
            tracking: trackingWithContext,
            name: trackingName,
            abTestingPopulation,
            segType,
            version: $_BUILD_APP_VERSION,
            pageURL,
            offerLocation,
            isKids,
          };

          if (!isTvDevice) {
            launchExternalServiceWeb(detail, dataTracking);
          } else {
            launchExternalServiceTv(detail, dataTracking);
          }
        } catch (error) {
          Logger.error(`ExternalService::launchExternalService() - Error occurs during launchExternalService ${error}`);
        }
        break;

      case HANDLE_EXTERNAL_SERVICE_TRACKING:
        try {
          let tracking: DataLayerTracking | ApiV2PageTrackingDataLayer | undefined;
          const { URLPage, contentID, urlWebsite, trackingContext } = (action as IHandleExternalServiceTrackingAction)
            .payload;

          const context = effectiveTrackingContextSelector(state);
          const abTestingPopulation = abTestingPopulationSelector(state);
          const segType = segTypeSelector(state);
          const pageURL = pageURLSelector(state);
          const offerLocation = offerLocationSelector(state);
          const isKids = isKidsProfileSelector(state);
          const hodorSdkConfig = hodorSdkConfigSelector(state);

          if (URLPage && hodorSdkConfig) {
            // Call URLPage to get tracking object
            const response = (await requestPage(hodorSdkConfig, {
              url: URLPage,
            })) as any;

            tracking = response?.tracking?.dataLayer;
          } else {
            tracking = findTrackingSelector(state).dataLayer;
          }

          const trackingWithContext = { ...tracking, ...context, ...trackingContext };

          // Send tracking
          sendDeeplinkTrackingEvent({
            tracking: trackingWithContext,
            name: DEEPLINK,
            abTestingPopulation,
            segType,
            version: $_BUILD_APP_VERSION,
            pageURL,
            offerLocation,
            isKids,
            ...(contentID && { contentID }),
            ...(urlWebsite && { urlWebsite }),
          });
        } catch (error) {
          Logger.error(
            `ExternalService::handleExternalServiceTracking() - Error occurs during handleExternalServiceTracking ${error}`
          );
        }

        break;

      default:
        break;
    }

    return next(action);
  };
