import type { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';

export enum ActionExternalServicesTypeKeys {
  LAUNCH_EXTERNAL_SERVICE = 'LAUNCH_EXTERNAL_SERVICE',
  HANDLE_EXTERNAL_SERVICE_TRACKING = 'HANDLE_EXTERNAL_SERVICE_TRACKING',
}

export interface ILaunchExternalServiceAction {
  readonly type: ActionExternalServicesTypeKeys.LAUNCH_EXTERNAL_SERVICE;
  readonly payload: { URLMedias: string; isDownload: boolean };
}

export const launchExternalService = (
  URLMedias: string,
  isDownload: boolean = false
): ILaunchExternalServiceAction => ({
  payload: { URLMedias, isDownload },
  type: ActionExternalServicesTypeKeys.LAUNCH_EXTERNAL_SERVICE,
});

export interface IHandleExternalServiceTrackingAction {
  readonly type: ActionExternalServicesTypeKeys.HANDLE_EXTERNAL_SERVICE_TRACKING;
  readonly payload: { URLPage?: string; contentID?: string; urlWebsite?: string; trackingContext?: ApiV2Context };
}

export const handleExternalServiceTracking = (
  URLPage?: string,
  contentID?: string,
  urlWebsite?: string,
  trackingContext?: ApiV2Context
): IHandleExternalServiceTrackingAction => ({
  payload: { URLPage, contentID, urlWebsite, trackingContext },
  type: ActionExternalServicesTypeKeys.HANDLE_EXTERNAL_SERVICE_TRACKING,
});

export type ExternalServiceActionTypes = ILaunchExternalServiceAction | IHandleExternalServiceTrackingAction;
