import { getClientId } from '@canalplus/mycanal-commons';
import { Context, Pass } from '@canalplus/mycanal-sdk';
import { Environment, PassPlatform } from '@canalplus/sdk-core';
import { getPublicConfig } from '../../helpers/config/config-helper';
import Logger from '../../helpers/logger/logger-helper';
import {
  offerLocationSelector,
  offerZoneSelector,
  platformSelector,
  portailIdSelector,
} from '../../selectors/application/application-selectors';
import { displayTVModeSelector } from '../../store/slices/displayMode-selectors';
import type { IState } from '../../store/types/State-type';

export function initPass(state: IState): void {
  const publicConfig = getPublicConfig();
  const environment = publicConfig.environment || Environment.PROD;
  const mock = publicConfig.mock;
  const offerLocation = offerLocationSelector(state);
  const offerZone = offerZoneSelector(state);
  const portailId = portailIdSelector(state);
  const isTvDevice = displayTVModeSelector(state);
  const platform = platformSelector(state);
  const media = PassPlatform[platform];

  Pass.initialize(
    {
      application: publicConfig.api.pass.applicationKey,
      clientId: getClientId(getPublicConfig().api.pass.clientIds, window.location.href),
      context: Context.CLIENT,
      endpoint: publicConfig.api.pass.endpoint, // In mock context, we need to override the endpoint parameter
      environment,
      isPrivate: publicConfig.api.pass.isPrivate,
      isTvDevice,
      logger: Logger,
      media,
      mock,
      offerLocation,
      operator: publicConfig.api.pass.operator,
      portailId,
      redirect: window.location.href,
      offerZone,
    },
    undefined
  );
}
