import { SEQUENCES_TYPES } from '../CorePlayers/SequenceTypes';
// ContentFunctionalTypes
export const PRIMARY = 'primary';
export const TRAILER = 'trailer';

export const HAPI_PRIMARY = 'MOVIE';
export const HAPI_TRAILER = 'TEASER';

// drmType HAPI
export const DRM_COMMON_ENCRYPTION = 'DRM_COMMON_ENCRYPTION';
export const DRM_COMMON_ENCRYPTION_DOWNLOAD = 'DRM_COMMON_ENCRYPTION_DOWNLOAD';
export const DRM_APPLE = 'DRM_APPLE';
export const DRM_WIDEVINE = 'DRM_WIDEVINE';
export const DRM_WIDEVINE_DOWNLOAD = 'DRM_PLAYREADY_DOWNLOAD';
export const DRM_PLAYREADY = 'DRM_PLAYREADY';
export const UNPROTECTED = 'UNPROTECTED';
export const DEEPLINK = 'DEEPLINK';
export const DRM_MKPC_WIDEVINE_DASH = 'DRM_MKPC_WIDEVINE_DASH';
export const DRM_MKPC_PLAYREADY_DASH = 'DRM_MKPC_PLAYREADY_DASH';
// For the DRMTYPE below, we have two drmType with the same drmType name, to differientiate them, we have to look at distTechnology
export const DRM_MKPC_COMMON_ENCRYPTION_DASH_DOWNLOAD =
  'DRM_MKPC_COMMON_ENCRYPTION_DASH_DOWNLOAD';

/**
 * SKPL = Single Key per License,
 * means that we will have to make multiples calls to the license server provider
 * for each group of quality when we encounter a new keyId that we didnt encounter yet.
 */
export const SKPL = 'SKPL';
/**
 * MKPL = Multiple Key per License
 * means that we will have to make just one call (most of the time) to the license
 * server provider, because every decryption key will be wrapped in the license.
 * The major benefit to do that, is to only perform one single call that save ressources.
 */
export const MKPL = 'MKPL';

export const SUPPORTED_DRM_TYPES_HR = {
  DRM_MKPC_PLAYREADY_DASH: 'playready',
  DRM_MKPC_WIDEVINE_DASH: 'widevine',
  DRM_WIDEVINE: 'widevine',
  DRM_PLAYREADY: 'playready',
  DRM_APPLE: 'fairplay',
};

export const PLAYSET_DRM_TYPES_WIDEVINE = [
  UNPROTECTED,
  DRM_MKPC_WIDEVINE_DASH,
  DRM_WIDEVINE,
];

export const PLAYSET_DRM_TYPES_PLAYREADY = [
  UNPROTECTED,
  DRM_MKPC_PLAYREADY_DASH,
  DRM_PLAYREADY,
];

export const PLAYSET_DRM_TYPES_FAIRPLAY = [
  UNPROTECTED, // DRM Free
  DRM_APPLE, // Fairplay
];

export const mapPlaysetDrmTypeToDrmEnv = {
  widevine: PLAYSET_DRM_TYPES_WIDEVINE,
  playready: PLAYSET_DRM_TYPES_PLAYREADY,
  fairplay: PLAYSET_DRM_TYPES_FAIRPLAY,
};

// distTechnology HAPI
export const DIST_TECH_DOWNLOAD = 'DOWNLOAD';
export const DIST_TECH_STREAM = 'STREAM';

// Hapi ContentTypes
export const HAPI_TYPES = {
  SERIES: 'series',
  MOVIE: 'movie',
  PROGRAM: 'PROGRAM',
};

export const OFFER_ZONE = {
  CPFRA: 'cpfra',
  CPAFR: 'cpafr',
  CPCHD: 'cpchd',
  CPCHE: 'cpche',
  CPREU: 'cpreu',
  CPANT: 'cpant',
  CPETH: 'cpeth',
  CPHTI: 'cphti',
  CPMUS: 'cpmus',
  CPNCL: 'cpncl',
  CPMDG: 'cpmdg',
  CPPOL: 'cppol',
  CPOTH: 'cpoth',
  CPPYF: 'cppyf',
  CPENG: 'cpeng', // used on myCanal repo
  TIITA: 'tiita',
  CBANT: 'cbant', // used on myCanal repo
  CBREU: 'cbreu', // used on myCanal repo
} as const;

/** All Canal+ OfferLocations */
export const OFFER_LOCATION = {
  BL: 'bl',
  CH: 'ch',
  EN: 'en',
  FR: 'fr',
  GF: 'gf',
  GP: 'gp',
  MF: 'mf',
  MQ: 'mq',
  MU: 'mu',
  NC: 'nc',
  OT: 'ot',
  PF: 'pf',
  PL: 'pl',
  RE: 're',
  WF: 'wf',
  YT: 'yt',
  KM: 'km',
  VU: 'vu',
  BF: 'bf',
  BI: 'bi',
  BJ: 'bj',
  CG: 'cg',
  CI: 'ci',
  CM: 'cm',
  CV: 'cv',
  DJ: 'dj',
  GA: 'ga',
  GH: 'gh',
  GM: 'gm',
  GN: 'gn',
  GQ: 'gq',
  GW: 'gw',
  ML: 'ml',
  MR: 'mr',
  NE: 'ne',
  NG: 'ng',
  CD: 'cd',
  CF: 'cf',
  RW: 'rw',
  SL: 'sl',
  SN: 'sn',
  TD: 'td',
  TG: 'tg',
  MG: 'mg',
  MC: 'mc',
  AD: 'ad',
  DO: 'do',
  HT: 'ht',
  TT: 'tt',
  IT: 'it',
  ET: 'et',
} as const;

type TPARENTAL_RATING_AUTHORITY = 'CSA' | 'KRRIT' | 'IRS' | '';
export const PARENTAL_RATING_AUTHORITY: {
  [key in (typeof OFFER_ZONE)[keyof typeof OFFER_ZONE]]: TPARENTAL_RATING_AUTHORITY;
} = {
  cpfra: 'CSA',
  cppol: 'KRRIT',
  cpafr: '',
  cpchd: 'CSA',
  cpche: '',
  cpreu: '',
  cpant: '',
  cpeth: '',
  cphti: '',
  cpmus: '',
  cpncl: '',
  cpmdg: '',
  cpoth: '',
  cppyf: '',
  cpeng: '',
  tiita: 'IRS',
  cbant: '',
  cbreu: '',
};

export const DEFAULT_PARENTAL_RATING_AUTHORITY =
  PARENTAL_RATING_AUTHORITY[OFFER_ZONE.CPFRA];

// Context Types
export const MY_CANAL = 'mycanal';

// Environment Types
export const PROD_ENV = 'prod';

// Ending Behaviors
export const endingBehaviors = {
  POSTER: 'poster',
  LOOP: 'loop',
  NONE: 'none',
};

// Group Types
export const DEFAULT_GROUP_TYPES = '1';
export const ZAPLIST_GROUPTYPE_TO_DISPLAY = 1;

// Live Types
export const LIVE_TYPES = {
  LIVE_CHANNEL: 'CHAINE LIVE',
  LIVE_EVENT: 'EVENEMENT LIVE',
  LIVE_DEFAULT: 'LIVE',
};

// Modal Types
export const MODAL_TYPES = {
  LANGUAGE: 'LANGUAGE',
  SUBTITLES_PARAMETERS: 'SUBTITLES_PARAMETERS',
  PARENTAL: 'PARENTAL',
  DEBUG: 'DEBUG',
  LOCAL_REDIRECTION: 'LOCAL_REDIRECTION',
  HELP: 'HELP',
  PLAYBACK_RATE: 'PLAYBACK_RATE',
  AUDIO_ONLY: 'AUDIO_ONLY',
  SETTINGS: 'SETTINGS',
  QUALITY_CONTROL: 'QUALITY_CONTROL',
  EXPERT_MODE_SURVEY: 'EXPERT_MODE_SURVEY',
  SLEEP_MODAL: 'SLEEP_MODAL',
  CONTENT_DISRUPTING_MODAL: 'CONTENT_DISRUPTING_MODAL',
  PARENTAL_CODE: 'PARENTAL_CODE',
} as const; // Using const assertion makes type of each key equal to the value instead of the type of value
// (eg type will be {HELP: 'HELP'}  instead of {HELP: string}). This allows stronger typing
// For more info please read : https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-4.html#const-assertions

// Default offer zone types
export const DEFAULT_OFFER_ZONE = OFFER_ZONE.CPFRA;
export const FRANCE_OFFER_ZONE = OFFER_ZONE.CPFRA;
export const POLAND_OFFER_ZONE = OFFER_ZONE.CPPOL;
export const TIM_ITALY_OFFER_ZONE = OFFER_ZONE.TIITA;

// PlayBack Types
export const PLAYBACK_TYPES = {
  PLAYBACKRATE_DEFAULT: 1,
  AVAILABLE_PLAYBACKRATE_VALUES: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
};

// Seek MINUS/PLUS Duration types
// If you change these durations, remember to update the text in the button SVGs
export const SEEK_ARROW_DURATION = {
  SEEK_MINUS_FIXED_DURATION: 10,
  SEEK_PLUS_FIXED_DURATION: 10,
};

// SidePanel name types
export enum SIDEPANEL_TYPES {
  SERIES = 'SERIES',
  ZAPLIST = 'ZAPLIST',
  TIMELINE = 'TIMELINE',
  PROGRAM = 'PROGRAM',
  EXPERT_MODE = 'EXPERT_MODE',
}

// Streaming Types
export enum STREAMING_TYPES {
  DASH = 'dash',
  SMOOTH = 'smooth',
  HLS = 'hls',
  STREAMING_TYPE_DIRECTFILE = 'directfile',
}

// Type of content playable
export const CONTENT_TYPE_PLAYABLE = {
  ADS: 'ads',
  VOD: 'vod',
  LIVE: 'live',
};

// Meta Types
export const META_TYPES = {
  META_ACTION_HISTORY: 'ACTION_HISTORY',
  META_CALLER: 'CALLER',
};

// Program types
export enum PROGRAM_TYPES {
  TIMELINE_PREV = 'TIMELINE_PREV',
  TIMELINE_NEXT = 'TIMELINE_NEXT',
}

export const TIMELINE_MENU = [
  {
    value: PROGRAM_TYPES.TIMELINE_PREV,
    label: 'Replay',
    actionType: 'listPreviousPgBtPressed',
  },
  {
    value: PROGRAM_TYPES.TIMELINE_NEXT,
    label: 'Upcoming',
    actionType: 'listNextPgBtPressed',
  },
];

export const MEDIAMETADATA_ARTWORK_SIZE = 400;

export const TV_PANEL_MAIN = 'MAIN';
export const TV_PANEL_MORE_INFOS = 'INFOS';
export const TV_PANEL_TIMELINE = 'TIMELINE';
export const TV_PANEL_ZAPLIST = 'ZAPLIST';
export const TV_PANEL_EPISODES = 'EPISODES';
export const TV_PANEL_PARENTAL_CODE = 'PARENTAL_CODE';
export const TV_PANEL_EXPERT_MODE = 'EXPERT_MODE';

export const FOCUS_PROGRESSBAR = 'focusProgressbar';
export const FOCUS_MENU = 'focusMenu';

export const SOURCE_BUTTONS = 'buttons'; // Related to SkipButtons
export const SOURCE_MODAL = 'modal';
export const SOURCE_PANEL = 'panel';

export const DEFAULT_LATENCY = -10;
export const MEDIAMETADATA_ARTWORK_SIZES = {
  width: 400,
  height: 400,
};

export const POSTER_SIZES = {
  width: 640,
  height: 360,
};

// Subtitles Parameters types
export enum SUBTITLES_SIZE {
  VERYSMALL = 'verysmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  VERYLARGE = 'verylarge',
}

export const SUBTITLES_FONT_FAMILY = {
  DYSTITLES: 'dystitles',
} as const;

export const SUBTITLES_FONT_TYPE = {
  DEFAULT: 'default_font_type',
  DYSLEXIC: 'dyslexic_font_type',
} as const;

export const SUBTITLE_SIZE_SCALE_FACTOR = {
  SCALE_VERYSMALL: 0.3,
  SCALE_SMALL: 0.5,
  SCALE_MEDIUM: 0.68,
  SCALE_LARGE: 0.8,
  SCALE_VERYLARGE: 1,
};

export enum SUBTITLES_PRESET {
  SHADOW = 'shadow',
  DEFAULT = 'default',
  ARTE = 'arte',
  BLACK = 'black',
}

export const SUBTITLES_DEFAULT_SETTINGS = {
  DEFAULT_SIZE: SUBTITLES_SIZE.MEDIUM,
  DEFAULT_PRESET: SUBTITLES_PRESET.DEFAULT,
};

// Cast custom message types
export const CAST_MESSAGE_TYPE = {
  SUBTITLES_FONT_FAMILY: 'subtitlesFontFamily',
  SUBTITLES_FONT_TYPE: 'subtitlesFontType',
  SUBTITLES_PRESET: 'subtitlesPreset',
  SUBTITLES_SIZE: 'subtitlesSize',
  REFERENCE_DATE_TIME: 'referenceDateTime',
  CANCEL_NEXT_EPISODE_COUNTDOWN: 'cancelNextEpisodeCountdown',
  TOGGLE_RX_PLAYER_DEBUG_MODAL: 'toggleRxPlayerDebugModal',
};

export const PLATFORMS = {
  HAPI: 'hapi',
  LIVE: 'live',
  MULTI: 'multi',
  PLATFORM_DIRECTFILE: 'directfile',
  MANUAL_HLS: 'manual-hls',
  MANUAL_RX: 'manual-rx',
};

export const PLAYER_TYPES = {
  PLAYER_MULTICAM: 'playerMulticam',
  PLAYER_HIGHLIGHTS: 'videoHighlights',
};

// Themes decide what root UI/UX component will be loaded changing the whole interface
export enum THEMES {
  WEB = 'web',
  TV = 'tv',
}

// Theme variant change only light aesthetic features like colors, or anything not too heavy in CSS
export enum VARIANTS {
  MYCANAL = 'mycanal',
  MYCANAL_TVOD = 'mycanal_tvod',
  TELECOMITALIA = 'telecomitalia',
}

// Each new theme offers a set of quick styles or features related to a device.
export enum DEVICE_TYPES {
  CHROMECAST = 'chromecast',
  PLAYSTATION_4 = 'playstation4',
  PLAYSTATION_5 = 'playstation5',
  PHILIPS = 'philips',
  SAMSUNG = 'samsung',
  LG = 'lg',
  HISENSE = 'hisense',
  FIRETVREACT = 'firetvreact',
  DESKTOP_MOBILE = 'web',
  G9_TECHNICOLOR = 'g9technicolor',
  G9L_TECHNICOLOR = 'g9ltechnicolor',
  G9MINI_HUMAX = 'g9minihumax',
  G9MINIVE_HUMAX = 'g9minivehumax',
  G10_HUMAX = 'g10humax',
  G10_SAGEMCOM = 'g10sagemcom',
  G10CPI_SAGEMCOM = 'g10cpisagemcom',
  G11 = 'g11',
  MIB4 = 'mib4',
  XBOX = 'xbox',
  UWP = 'uwp',
  TV_KEY = 'cletv',
}

// Possible xbox model (provided by ucaya)
export const XBOX_DEVICE_NAMES = {
  XBOX_ONE: 'Xbox One',
  XBOX_ONE_S: 'Xbox One S',
  XBOX_ONE_X: 'Xbox One X',
  XBOX_SERIES_S: 'Xbox Series S',
  XBOX_SERIES_X: 'Xbox Series X',
} as const;

export const PLAYER_PACKAGE = {
  HLS_PLAYER: 'hls',
  RX_PLAYER: 'rx',
};

// Review's name
export const REVIEWS_NAME = {
  ALLOCINE: 'Allociné',
  TELERAMA: 'Télérama',
  TELECABLE: 'Télécâble Sat',
};

// skipButtons
export const SKIP_BUTTONS = {
  NEXT_EPISODE: 'NEXT_EPISODE',
  CANCEL_COUNTDOWN: 'CANCEL_COUNTDOWN',
  SKIP_CONTENT: 'SKIP_CONTENT',
  ANTI_AD_SKIP: 'ANTI_AD_SKIP',
};

// context menu types
export const CONTEXT_MENU_TYPES = {
  CONTEXT_MENU_MODAL: 'modal',
  CONTEXT_MENU_LINK: 'link',
};

// parental code status after checking the parental code entered by the user
export enum PARENTAL_CODE_STATUS {
  VALID = 'valid',
  INVALID = 'invalid',
  LOCKED = 'locked',
}

// Chromecast APP_ID
export const APP_ID = {
  PROD: 'F4A5D26F', // prod APP_ID for default offerZone cpfra
  PREPROD: '0FD909DB', // preprod APP_ID
  NGROK_01: '34542F67',
  NGROK_01_EU: 'F03A3D52', // .eu.ngrok.io for Ngrok V3
  NGROK_02: '57352B42',
  NGROK_02_EU: '14841971', // .eu.ngrok.io for Ngrok V3
  TIM: '45BB6F89', // telecom italia APP_ID
};

// Mapping between HD epgId and UHD epgId for Chromecast
export const HD_TO_UHD_CHANNELS_MAPPING: { [key: string | number]: number } = {
  301: 728,
};
export const UHD_TO_HD_CHANNELS_MAPPING: { [key: string | number]: number } = {
  728: 301,
};

// This enum refers to the 2 environments we have on the project
// when we deploy a new version of the player. We are deploying
// gradually our new version: the blue environement is the stable version
// (usually the last version we deployed) and the green is the environment
// with the newest version of the player that we are trying to deploy.
// The green environment is targeted gradually by a largest amount of users
// during a release until it reaches 100% of the users. When all users
// are targeted by the green environment and we are sure it stable,
// we push the green environment onto the blue environment.
export enum ENVIRONMENT {
  BLUE = 'blue',
  GREEN = 'green',
}

export enum KRRIT_ADVISORY {
  N = 'N',
  P = 'P',
  S = 'S',
  W = 'W',
}

export const ZAP_ORIGIN = {
  EXTERNAL: 'external',
  REMOTE: 'zapP+P-',
  ZAPLIST: 'zaplist',
} as const;

export const SECURITY_LEVELS = {
  L1: 'L1',
  L2: 'L2',
  L3: 'L3',
  SL2000: 'SL2000',
  SL3000: 'SL3000',
} as const;

export const SPONSOR_GENRES = {
  SPONSO: 'sponso',
  JINGLE: 'jingle',
  TRAILER: 'trailer',
} as const;

/**
 * @description mapping between sequenceTypes and contentTypes for SwitchPlus and PPL
 * 0 : Autre
 * 1 : Panneau Parental Rating / CSA
 * 2 : Preroll
 * 3 : Sponsor
 * 4 : VoD = Catch'up = Services ALD.
 * 5 : Live TV
 * 6 : Bande annonce
 * 7 : Canalplay SVOD
 * 8 : CanalPlay TVOD dans SVOD
 * 9 : CanalPlay TVOD
 * 10 : StartOver
 * 11 : PdL (Progressive Download)
 * 12 : PVR
 * 13 : Download To Go
 * 14 : Postroll
 * 100 : Peer2Peer"
 */
export const contentTypes = {
  [SEQUENCES_TYPES.PARENTAL_RATING]: 1,
  [SEQUENCES_TYPES.PREROLL]: 2,
  [SEQUENCES_TYPES.POSTROLL]: 14,
  [SEQUENCES_TYPES.MIDROLL]: 0, // TODO: set correct value
};

/**
 * Application ID for pinglog url
 */
export const pinglogApplicationId = {
  vod: 4,
  live: 1,
};

export const distTypes = {
  catchup: 4,
  live: 5,
  trailer: 6,
  svod: 7,
  tvod: 9,
};

export const timeCodeTypes = {
  ads: 6,
};
