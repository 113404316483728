import { getCookie, getQsValueByKey } from '@canalplus/mycanal-commons';
import { IUserInformation, IUserSettings, Pass } from '@canalplus/mycanal-sdk';
import { OfferZone } from '@canalplus/sdk-core';
import { ApiV2Profile } from '@dce-front/hodor-types/api/v2/me/profiles/definitions';
import { PASS_TOKEN_VALIDITY } from '../../constants/limits';
import { Queries } from '../../constants/url';
import { getCurrentProfile, removeAllProfiles } from '../../services/profiles/profileStorageService';
import { refreshProfileOneCore } from '../r7/r7-helpers';
import { isR7Available } from '../window/window-helper';

/**
 * Get an encoded **passID** from the last `p_pass_token` cookie\
 * Each time we pass a passId, we need to encode it!
 *
 * @returns The **encoded passId** or `undefined`
 */
export const getEncodedPassId = (): string | undefined => {
  const passId = getCookie(Pass.passIdCookie);

  if (passId) {
    return encodeURIComponent(passId);
  }

  return undefined;
};

/**
 * This function will retrieve user info through
 * the proper Pass function.
 */
export const getClientSideUserInfos = async (
  options: { cache?: boolean; offerZone?: OfferZone; settings?: IUserSettings } = {}
): Promise<IUserInformation> => {
  // On OneDiscovery, we need use method oneCore refreshProfile to get token
  // feature #9735
  if (isR7Available()) {
    return refreshProfileOneCore();
  }
  return Pass.getUserInfos(options);
};

/**
 * Handle client side redirection to select region page
 *
 */
export const clientSideSelectRegionRedirection = (contentID?: string): void => {
  if (isR7Available()) {
    window.R7('navigate', {
      skey: 'SELECT_REGION',
      context: contentID ? { deeplink: { contentId: contentID } } : undefined,
    });
  }
};

/**
 * Handle client-side redirection to oneshop webapp
 */
export const clientSideOneShopRedirection = (contentID?: string): void => {
  if (isR7Available()) {
    window.R7('navigate', {
      skey: 'ONE_SHOP',
      context: contentID ? { deeplink: { contentId: contentID } } : undefined,
    });
  }
};

/**
 * Handle client side redirection to authentication page
 */
export const logOut = (): void => {
  removeAllProfiles();

  if (isR7Available()) {
    Pass.cleanCookies();
    window.R7('logoutUser', {});
    return;
  }
  Pass.disconnect();
};

export const sortProfiles = (profiles: ApiV2Profile[]): ApiV2Profile[] => {
  const currentProfile = getCurrentProfile();

  const defaultProfileIndex = profiles.findIndex(
    (profile) => currentProfile && profile.profileId === currentProfile.profileId
  );

  const sortedProfiles = [...profiles];

  if (defaultProfileIndex > -1) {
    sortedProfiles.splice(defaultProfileIndex, 1);
    sortedProfiles.unshift(profiles[defaultProfileIndex]);
  }

  return sortedProfiles;
};

export const isPassTokenExpired = (passTokenCreationDate: number): boolean => {
  // passToken must be renewed if created more than 4 hours ago (=14400000ms)
  const endValidityDate = new Date().getTime() - PASS_TOKEN_VALIDITY;
  return passTokenCreationDate <= endValidityDate;
};

export const generatePassToken = async (): Promise<string> => {
  const userInfos = await getClientSideUserInfos();

  return userInfos?.passToken;
};

export const isDiscoverMode = (subscriberId?: number): boolean => subscriberId !== undefined && subscriberId < 0;

export const shouldUsePassCache = (): boolean => {
  if (
    getQsValueByKey(window.location.href, 'passCache') === 'false' ||
    getQsValueByKey(window.location.href, Queries.RefreshRight) === 'true'
  ) {
    return false;
  }
  return true;
};

export interface IUserData {
  authenticated: boolean;
  macroEligibility: string;
  microEligibility: string;
  offerZone: string;
  epgidOTT: string;
  epgid: string;
  reco: boolean;
  analyticsId: string;
  dmpId: string;
}
/**
 * This formatter helps extract from the user data the only piece of information that we are allowed to store on the server side /
 * i.e the only data that are allowed to be cached by Akamai
 */
export const formatUserData = ({
  isAuthenticated,
  macroEligibility,
  microEligibility,
  offerZone,
  epgid_OTT,
  epgid,
  reco,
  id_analytics,
  dmpId,
}: IUserInformation): IUserData => ({
  authenticated: isAuthenticated,
  epgidOTT: epgid_OTT,
  epgid,
  macroEligibility,
  microEligibility,
  offerZone,
  reco,
  analyticsId: id_analytics,
  dmpId,
});
