import { isSomeEnum } from '@canalplus/mycanal-commons';
import { IUserInformation, Pass } from '@canalplus/mycanal-sdk';
import { Platform } from '@canalplus/sdk-core';
import { isResizeMode } from '../constants/resize';
import { Queries } from '../constants/url';
import '../globals';
import { importDiveTokens } from '../helpers/application/application-helper';
import { getLocaleData, getPublicConfig } from '../helpers/config/config-helper';
import { loadClientTranslations } from '../helpers/lang/lang-helper';
import Logger from '../helpers/logger/logger-helper';
import { markPerformance } from '../helpers/performance/markPerformance';
import { setInitialQueryParams } from '../helpers/r7/r7-helpers';
import { initializeManualSettingsOverride } from '../helpers/settings/settings-helper';
import { getUserAgent } from '../helpers/userAgent/userAgent-helper';
import { initializewaitForPassToken } from '../helpers/waitForPassToken/waitForPassToken-helper';
import { getCookieWhoIsWatchingDisabled, getCookieWhoIsWatchingSession } from '../helpers/whoIsWatching/cookieHelper';
import { getFeatureToggleBlueTim, getThemeSelector } from '../selectors/application/application-selectors';
import { performHodorCinematicUniversal } from '../services/Hodor/hodor-services';
import { getIsomorphicStore } from '../store/getIsomorphicStore';
import {
  hideFooter,
  setApplicationResize,
  setContextInstantiation,
  setUserAgent,
  setWhoIsWatchingDisabled,
  setWhoIsWatchingUserSession,
  setZoneInfo,
} from '../store/reducers/actions/Application-actions';
import { setupAsTV } from '../store/slices/displayMode';
import { screenResize } from '../store/slices/ui';
import { getPassToken, receiveUserData } from '../store/slices/user';
import { initPass } from './components/initPass';
import removeServiceWorkers from './components/removeServiceWorkers';

// Load base CSS after sharedcomponent CSS give mycanal variables greater priority
import '@canalplus/dive/dist/css/fonts.css';
import '@canalplus/mycanal-sharedcomponent/dist/css/index.css';
import '../assets/base_css/base.css';
import { initializeIfc } from '../helpers/ifc/ifc-onecore.initialize';

export default async function client(inputUserInfo?: IUserInformation, ifcAlreadyInitialized?: boolean): Promise<void> {
  let userInfos = inputUserInfo;

  // eslint-disable-next-line no-console
  console.info('Build Info', {
    // We do not use shorthand here since variable is replaced but webpack
    /* eslint-disable object-shorthand */
    $_BUILD_RENDERMODE_CSR: $_BUILD_RENDERMODE_CSR,
    $_BUILD_RENDERMODE_SSR: $_BUILD_RENDERMODE_SSR,
    $_BUILD_DEVICE_DEFAULT: $_BUILD_DEVICE_DEFAULT,
    $_BUILD_DEVICE_ORANGEBOX: $_BUILD_DEVICE_ORANGEBOX,
    $_BUILD_TARGET_WEB: $_BUILD_TARGET_WEB,
    $_BUILD_TARGET_NODE: $_BUILD_TARGET_NODE,
    $_BUILD_APP_VERSION: $_BUILD_APP_VERSION,
    $_BUILD_APP_GIT_REF: $_BUILD_APP_GIT_REF,
    /* eslint-enable */
  });

  // For playstation we need apply some different CSS values
  // (Example overflow hidden to deactivate native scroll on elements)
  if (navigator.userAgent.toLocaleLowerCase().includes('playstation')) {
    document.documentElement.classList.add('device-playstation');
  }

  const {
    api,
    defaultLocale,
    api: {
      hodor: { defaultAppKey },
    },
  } = getPublicConfig();
  const query = new URLSearchParams(window.location.search);
  const isTvDevice = query.get(Queries.Display) === 'tv';
  const offerLocationQuery = query.get(Queries.OfferLocation);
  const offerZoneQuery = query.get(Queries.OfferZone);

  // Init IFC onecore
  if (isTvDevice && !ifcAlreadyInitialized) {
    initializeIfc();
  }

  // This is populated on one-discovery by the buffer page
  const initialHistoryEntries = JSON.parse(query.get(Queries.InitialHistoryEntries) || '[]');

  const { store, history } = getIsomorphicStore({ initialHistoryEntries, isTvDevice });

  markPerformance('start', store.getState());

  // On OD we use the initial history entries provided by buffer page, on web use use
  // location.pathname directly
  const pathnameForLocaleData = isTvDevice ? initialHistoryEntries[0] : window.location.pathname;

  const locale = getLocaleData(isTvDevice, pathnameForLocaleData, offerLocationQuery, offerZoneQuery);
  const { offerLocation, lang, portailId, offerZone, appKey } = locale;
  const platformQuery = query.get(Queries.Platform);
  const platform = platformQuery && isSomeEnum(Platform)(platformQuery) ? platformQuery : undefined;
  const resizeModeQuery = query.get(Queries.Resize);
  const resizeMode = isResizeMode(resizeModeQuery) ? resizeModeQuery : undefined;

  // Save initial search queries
  setInitialQueryParams(window.location.search);

  if (isTvDevice && resizeMode) {
    store.dispatch(setApplicationResize(resizeMode));
  }

  // For CSR mode (like oneDiscovery in pure SPA mode), initialize store with stuff done in SSR
  if ($_BUILD_RENDERMODE_CSR) {
    if (isTvDevice) {
      store.dispatch(setupAsTV());
      store.dispatch(hideFooter(true));
      if (platform) {
        store.dispatch(setContextInstantiation(platform));
      } else {
        Logger.error(`[Client] Set context error : ${platform} is unknown, check the ApplicationContext enum`);
      }

      // get whoIsWatching user settings from cookies and store it
      store.dispatch(setWhoIsWatchingUserSession(getCookieWhoIsWatchingSession()));
      store.dispatch(setWhoIsWatchingDisabled(getCookieWhoIsWatchingDisabled()));
    }
    store.dispatch(
      setZoneInfo(appKey || defaultAppKey, lang || defaultLocale.langKey, offerLocation, offerZone, portailId)
    );
  }

  // Dispatch an action to test the mobile user agent
  // NOTE this action must be placed before renderSource action
  // because `isMobile` needs to be set with right value
  store.dispatch(setUserAgent(getUserAgent()));

  initializeManualSettingsOverride(store); // At the very start of the function so the manual override can be taken into account
  const state = store.getState();

  const theme = getThemeSelector(state);
  const isBlueTim = getFeatureToggleBlueTim(state);

  const applicationNode = document.getElementById('application');

  if (applicationNode === null) {
    throw new Error('No application node found');
  }

  initializewaitForPassToken(store);
  initPass(state);

  store.dispatch(screenResize(window.innerWidth));

  const rendererOptions = { store, target: applicationNode, history, userInfos };

  // In CSR mode we need to manually dispatch userInfos from one-core into state
  if ($_BUILD_RENDERMODE_CSR) {
    if (!userInfos) {
      // We enter here only when we're in static mode without one-core
      userInfos = await Pass.getUserInfos({
        code: query.get(Queries.Code) as string,
        offerLocation,
        offerZone,
        operator: api.pass.operator,
        passId: '', // TODO for static (expect oneCore) => getCookie passId
      });

      markPerformance('pass-auth-fetched', state);
    }

    store.dispatch(getPassToken(userInfos.passToken));
    store.dispatch(receiveUserData(userInfos));

    // Make the Hodor initialization cinematic on client-side for CSR rendering
    const [{ default: renderer }, authenticate] = await Promise.all([
      import(/* webpackChunkName: "renderer" */ './components/renderer'),
      performHodorCinematicUniversal({
        store,
      }),
      loadClientTranslations(`${locale.lang}-${offerLocation}`),
      importDiveTokens(isBlueTim, theme),
    ]);

    // For tvDevicesWith deeplink we have to initialize an history object with 2 entries : the homepage and the deeplink
    // page so that the user can back to home. However, when initializing the history entries, we do not have the "true" path
    // of the homepage yet (which can be "/", "/pl", or "/home-kids" depending on the context).
    // That's why we rewrite the history entries in a very hacky way after we get the authenticate result
    if (isTvDevice && initialHistoryEntries.length > 2 && authenticate?.arborescence?.[0].onClick?.path) {
      // VERY HACKY SHIT
      (history as any).entries[0].pathname = authenticate?.arborescence?.[0].onClick?.path;
    }

    await renderer(rendererOptions);
  } else {
    const [{ default: renderer }] = await Promise.all([
      import(/* webpackChunkName: "renderer" */ './components/renderer'),
      loadClientTranslations(`${locale.lang}-${offerLocation}`),
      importDiveTokens(isBlueTim, theme),
    ]);
    await renderer(rendererOptions);
  }

  removeServiceWorkers();
}
