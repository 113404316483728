import { Moods } from './mood';

export const PLAYLIST = 'playlist';
export const ONGOING = 'ongoing';
export const RECOMMENDATIONS = 'recommendations';

const HISTORY = 'history';

export const REMOVABLE_TYPES = [PLAYLIST, Moods.Like, Moods.Dislike, Moods.Neutral, HISTORY, ONGOING, RECOMMENDATIONS];

export const DEFAULT_NB_ITEM_FETCHED = 12;
export const PAGINATION_CONTENT_GRID = 50;

type LazyLoaderOptions = {
  initialDisplayCount: number;
  increment: number;
};

const contentGridLazyLoaderOptions: LazyLoaderOptions = {
  increment: 30,
  initialDisplayCount: 30,
};

const tvContentGridLazyLoaderOptions: LazyLoaderOptions = {
  increment: 4,
  initialDisplayCount: 16,
};

const searchTvContentGridLazyLoaderOptions: LazyLoaderOptions = {
  increment: 4,
  initialDisplayCount: 8,
};

const stratesLazyLoaderOptions: LazyLoaderOptions = {
  increment: 4,
  initialDisplayCount: 8,
};

const tvStratesLazyLoaderOptions: LazyLoaderOptions = {
  increment: 1,
  initialDisplayCount: 4,
};

export const getLazyLoaderContentGridOptions = (isTvDevice: boolean, isSearch: boolean): LazyLoaderOptions => {
  if (!isTvDevice && !isSearch) {
    return contentGridLazyLoaderOptions;
  }

  if (isSearch) {
    return searchTvContentGridLazyLoaderOptions;
  }

  return tvContentGridLazyLoaderOptions;
};

export const getLazyLoaderStratesOptions = (isTvDevice: boolean): LazyLoaderOptions => {
  if (!isTvDevice) {
    return stratesLazyLoaderOptions;
  }

  return tvStratesLazyLoaderOptions;
};

export enum StrateMode {
  LiveTv = 'liveTV',
  Perso = 'perso',
  Standard = 'standard',
}
