import { ApiV2InitResponse } from '@dce-front/hodor-types';
import { setAdToken, setExternalServicesInfo } from '../../../../store/slices/user';
import { storeProfiles } from './storeProfiles';

type SetUserDetailsParameters = {
  init: ApiV2InitResponse;
  store: Redux.CustomStore;
};

/** ⚠️ To be performed Client Side only */
export function setUserDetails({ store, init }: SetUserDetailsParameters): void {
  const { externalServices, profiles, adToken } = init.userDetails || {};

  if (externalServices) {
    store.dispatch(setExternalServicesInfo(externalServices));
  }

  if (adToken) {
    store.dispatch(setAdToken(adToken));
  }

  storeProfiles({ store, profiles });
}
