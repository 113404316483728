import { ApiV2NavigationItem } from '@dce-front/hodor-types/api/v2/authenticate/definitions';

/** Get all the paths of first level pages from authenticate */
export const getFirstLevelPageList = (arborescence: ApiV2NavigationItem[] = []): string[] =>
  arborescence.reduce<string[]>((acc, item) => {
    const firstLevelPagePath = item?.onClick?.path;

    if (typeof firstLevelPagePath === 'string') {
      return [...acc, firstLevelPagePath];
    }

    return acc;
  }, []);
