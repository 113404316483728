import { createSelector } from '@reduxjs/toolkit';
import type { SearchState } from '../../store/slices/search';
import type { IState } from '../../store/types/State-type';

const searchSelector = (state: IState): SearchState => state?.search;

export const isSearchActiveSelector = createSelector(searchSelector, (search) => search?.active);

export const searchQuerySelector = createSelector(searchSelector, (search) => search?.query);

export const searchTrackingSelector = createSelector(searchSelector, (search) => search?.tracking);
