import { isClientSide } from '@canalplus/mycanal-commons';
import { isR7Available } from '../../helpers/window/window-helper';
import { getFeatureToggleHdr, getFeatureToggleLowLatency } from '../../selectors/application/application-selectors';
import { AllActionsTypes } from '../reducers/actions/All-actions-types';
import { displayTVModeSelector } from '../slices/displayMode-selectors';
import { receiveUserData, receiveUserInfos, userChangeSettings, userListProfile } from '../slices/user';
import {
  isHDRSelector,
  isKidsProfileSelector,
  isLowLatencySelector,
  profileIdSelector,
  profileImgSelector,
} from '../slices/user-selectors';

function getOneCoreHDRValue(value?: boolean): 'on' | 'off' | 'auto' {
  switch (value) {
    case true:
      return 'on';
    case false:
      return 'off';
    default:
      return 'auto';
  }
}
/**
 * Reponsible for synchronisation between our state and one-core
 */
export const oneCoreSyncMiddleware: Redux.Middleware = (store) => {
  let hasSetUserConfigOnce = false;
  let hasSetHdrOnce = false;
  let hasSetLowLatencyOnce = false;

  return (next) => (action) => {
    const prevState = store.getState();

    next(action);

    const state = store.getState();

    // Early skip
    if (!isClientSide() || !isR7Available() || displayTVModeSelector(state) === false) {
      return;
    }

    switch ((action as AllActionsTypes).type) {
      // Handle profile switch to update one-core status
      case receiveUserData.type:
      case receiveUserInfos.type:
      case userListProfile.type: {
        const currentProfileId = profileIdSelector(state);
        const currentProfileImage = profileImgSelector(state);
        const currentProfileIsKids = isKidsProfileSelector(state);

        if (
          !hasSetUserConfigOnce ||
          currentProfileId !== profileIdSelector(prevState) ||
          currentProfileImage !== profileImgSelector(prevState) ||
          currentProfileIsKids !== isKidsProfileSelector(prevState)
        ) {
          window.R7('setUserConfig', {
            currentProfileId,
            currentProfileImage,
            currentProfileIsKids,
          });

          hasSetUserConfigOnce = true;
        }
        break;
      }
      // Handle settings that needs to be known by one-core
      case userChangeSettings.type: {
        if (getFeatureToggleHdr(state)) {
          const prevHdrValue = getOneCoreHDRValue(isHDRSelector(prevState));
          const nextHdrValue = getOneCoreHDRValue(isHDRSelector(state));

          if (prevHdrValue !== nextHdrValue || !hasSetHdrOnce) {
            window.R7('setUserConfig', {
              hdr: nextHdrValue,
            });

            hasSetHdrOnce = true;
          }
        }

        if (getFeatureToggleLowLatency(state)) {
          const prevLowLatencyValue = isLowLatencySelector(prevState);
          const nextLowLatencyValue = isLowLatencySelector(state);

          if (prevLowLatencyValue !== nextLowLatencyValue || !hasSetLowLatencyOnce) {
            window.R7('setUserConfig', {
              lowLatency: nextLowLatencyValue,
            });

            hasSetLowLatencyOnce = true;
          }
        }

        break;
      }
      default:
        break;
    }
  };
};
