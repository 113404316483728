import { isClientSide } from '@canalplus/mycanal-commons';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { AppHistory } from './typings/routing';

const createHistory = (initialEntries = ['/'], isTvDevice: boolean = false): AppHistory => {
  const options = { initialEntries, initialIndex: initialEntries.length - 1 };

  if (!isClientSide() || isTvDevice) {
    // Deeplinks for tv requires memory history
    return createMemoryHistory(options);
  }

  if (initialEntries.length) {
    console.error(`Creating a browser history with initial entries is not supported.`);
  }

  return createBrowserHistory();
};

export default createHistory;
