import { Build } from '../../constants/platform';

const MIN_CHROME_VERSION = 68;
const MIN_TIZEN_VERSION = 5.5;
const MIN_PLAYSTATION_VERSION = 5;

function isEs5UserAgent(ua: string, re: RegExp, minVersion: number): boolean | undefined {
  const match = ua.match(re);

  if (match?.[1]) {
    return parseFloat(match[1]) < minVersion;
  }

  return undefined;
}

function isEs5Chrome(ua) {
  return isEs5UserAgent(ua, /chr[o0]me\/(\d+\.\d+)/i, MIN_CHROME_VERSION);
}

function isEs5Tizen(ua) {
  return isEs5UserAgent(ua, /tizen\s(\d+\.\d+)/i, MIN_TIZEN_VERSION);
}

function isEs5Playstation(ua) {
  return isEs5UserAgent(ua, /playstation\s(\d+)/i, MIN_PLAYSTATION_VERSION);
}

function isEs5Canal(ua) {
  return !!ua.match(/canal-stb/i);
}

export function getBuild(ua: string): Build {
  const isEs5 = isEs5Chrome(ua) || isEs5Tizen(ua) || isEs5Playstation(ua) || isEs5Canal(ua);

  return isEs5 ? Build.ES5 : Build.ES6;
}
