export enum CookieKey {
  AbTestingPopulation = 'abTestingPopulation',
  DeviceId = 'deviceId',
  HodorKey = 'hodorKey',

  /** Stored as a string */
  IsKidsProfile = 'isKidsProfile',

  Offerzone = 'offerzone',

  Notification = 'notification',

  /** Stored as a string */
  ProfileId = 'profileId',

  WhoIsWatchingDisabled = 'whoIsWatchingDisabled',
  WhoIsWatchingSession = 'whoIsWatchingSession',

  TokenCMS = 'tokenCMS',
  Recommandations = 'recommandations',
}
