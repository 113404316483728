/**
 * A Storage implementation that handles JSON as value (instead of plain strings)
 * Returns an instance of JSONStorage
 */
export const jsonStorageFactory = (
  /**
   * The "backend" to use for storage, must match the localStorage API
   * @see https://developer.mozilla.org/fr/docs/Web/API/Window/localStorage
   */
  storageBackend: Storage
): JSONStorage => ({
  set(key: string, value: any): boolean {
    try {
      storageBackend.setItem(key, JSON.stringify(value));

      return true;
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';
      const errorStack = error instanceof Error ? error.stack : 'No stack';

      console.error(`JSONStorage: error while setting "${key}": ${errorMessage}\n${errorStack}`);

      return false;
    }
  },

  has(key: string): boolean {
    return !!storageBackend.getItem(key);
  },

  get<ValueType = unknown>(key: string): ValueType | null {
    const value = storageBackend.getItem(key);
    return value ? JSON.parse(value) : null;
  },

  remove(key: string): void {
    storageBackend.removeItem(key);
  },

  clear(): void {
    storageBackend.clear();
  },

  entries(): string[] {
    return Object.keys(storageBackend);
  },
});

/**
 * An instance of JSONStorage
 */
export type JSONStorage = {
  /**
   * Sets a value in session storage
   * Returns true if setItem succeed, false otherwise
   */
  set(
    /**
     * Entry key
     */
    key: string,
    /**
     * Entry value, anything that can be JSON.stringify-ied
     */
    value: any
  ): boolean;
  /**
   * Tests if an entry exists by key
   * Returns true if entry exists, false otherwise
   */
  has(key: string): boolean;
  /**
   * Retrieves the JSON.parse-d value of a key
   * Returns null if the entry doesn't exist
   */
  get<ValueType = unknown>(key: string): ValueType | null;
  /**
   * Removes an entry from the sessionStorage
   */
  remove(key: string): void;
  /**
   * Clears all entries in sessionStorage
   */
  clear(): void;
  /**
   *  Lists all entries keys
   */
  entries(): string[];
};
