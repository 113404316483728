import { removeCookie, setCookie } from '@canalplus/mycanal-commons';
import { ApiV2Profile } from '@dce-front/hodor-types/api/v2/me/profiles/definitions';
import { CookieKey } from '../../constants/cookie';
import { LocalStorageKey } from '../../constants/localStorage';
import { getCookieOptions } from '../../helpers/cookie/getCookieOptions';

/** Get **current profile** from localStorage. */
export const getCurrentProfile = (): ApiV2Profile | undefined => {
  const currentProfile = localStorage.getItem(LocalStorageKey.UserCurrentProfile);

  return currentProfile ? JSON.parse(currentProfile) : undefined;
};

/**
 * Set **current profile** on localStorage.
 *
 * Because **profileId** and **isKidsProfile** are used server side,\
 * we also set them as cookies.
 */
export const setCurrentProfile = (profile: ApiV2Profile): void => {
  const cookieOptions = getCookieOptions();

  if (profile.profileId !== undefined) {
    setCookie(CookieKey.ProfileId, String(profile.profileId), cookieOptions);
  }

  if (profile.isKidsProfile !== undefined) {
    setCookie(CookieKey.IsKidsProfile, String(profile.isKidsProfile), cookieOptions);
  }

  localStorage.setItem(LocalStorageKey.UserCurrentProfile, JSON.stringify(profile));
};

/** Get **profiles** from localStorage. */
export const getProfiles = (): ApiV2Profile[] | undefined => {
  const profiles = localStorage.getItem(LocalStorageKey.UserProfiles);

  return profiles ? JSON.parse(profiles) : undefined;
};

/** Set **profiles** on localStorage. */
export const setProfiles = (profiles: ApiV2Profile[]): void =>
  localStorage.setItem(LocalStorageKey.UserProfiles, JSON.stringify(profiles));

/** Get **profilesUpdateDate** from localStorage. */
export const getProfilesUpdateDate = (): string | undefined => {
  const profilesUpdateDate = localStorage.getItem(LocalStorageKey.UserProfilesUpdateDate);

  return profilesUpdateDate ? JSON.parse(profilesUpdateDate) : undefined;
};

/** Set **profilesUpdateDate** on localStorage. */
export const setProfilesUpdateDate = (profilesUpdateDate: string): void =>
  localStorage.setItem(LocalStorageKey.UserProfilesUpdateDate, JSON.stringify(profilesUpdateDate));

/**
 * Remove:
 * - all stored profiles data from localStorage
 * - **profileId** and **isKidsProfile** cookies
 */
export const removeAllProfiles = (): void => {
  removeCookie(CookieKey.ProfileId);
  removeCookie(CookieKey.IsKidsProfile);

  localStorage.removeItem(LocalStorageKey.UserCurrentProfile);
  localStorage.removeItem(LocalStorageKey.UserProfiles);
  localStorage.removeItem(LocalStorageKey.UserProfilesUpdateDate);
};

export type AllProfiles = {
  currentProfile: ReturnType<typeof getCurrentProfile>;
  profiles: ReturnType<typeof getProfiles>;
  profilesUpdateDate: ReturnType<typeof getProfilesUpdateDate>;
};

/** Get all stored profiles data from localStorage. */
export const getAllProfiles = (): AllProfiles => ({
  currentProfile: getCurrentProfile(),
  profiles: getProfiles(),
  profilesUpdateDate: getProfilesUpdateDate(),
});
