import { addQueryParam, removeQueriesFromUrl } from '@canalplus/mycanal-commons';
import createHistory from '../../createHistory';
import { getPublicConfig } from '../../helpers/config/config-helper';
import { AllActionsTypes } from '../reducers/actions/All-actions-types';
import { displayTVModeSelector } from '../slices/displayMode-selectors';
import { isImmersiveSelector } from '../slices/immersive-selectors';
import { pageDisplayTemplateSelector } from '../slices/page-selectors';
import { changeChannel, killPlayer, startPlayer } from '../slices/player';
import { routingLocationSelector } from '../slices/routing-selectors';

/**
 *  Change location search URL when needed by retrieving it in state
 */
export const transformUrlMiddleware: Redux.Middleware =
  ({ getState }) =>
  (next) =>
  (action) => {
    const state = getState();
    const location = routingLocationSelector(state);
    const displayTemplate = pageDisplayTemplateSelector(state);
    const isImmersive = isImmersiveSelector(state);
    const isLiveContent = displayTemplate === getPublicConfig().TEMPLATE.GABARIT_LIST && !isImmersive;
    const isTvDevice = displayTVModeSelector(state);
    const history = createHistory([], isTvDevice);

    switch ((action as AllActionsTypes).type) {
      case killPlayer.type: {
        if (isLiveContent && location) {
          const newSearch = removeQueriesFromUrl(location.search, ['channel']);
          history.replace({ ...location, search: newSearch });
        }
        break;
      }

      case startPlayer.type: {
        const epgID = ((action as ReturnType<typeof startPlayer>).payload.settings?.content as string) || null;

        if (epgID && isLiveContent && location) {
          const newSearch = addQueryParam(location.search, 'channel', epgID);
          history.push({ ...location, search: newSearch });
        }
        break;
      }

      case changeChannel.type: {
        const { epgID } = (action as ReturnType<typeof changeChannel>).payload;

        if (epgID && isLiveContent) {
          const newSearch = addQueryParam('', 'channel', epgID.toString());
          history.replace({ ...location, search: newSearch });
        }
        break;
      }

      default:
        break;
    }

    return next(action);
  };
