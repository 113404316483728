import { isSomeEnum } from '@canalplus/mycanal-commons';

export enum Url {
  Home = '/',
  HomePL = '/pl/',
  HomeCH = '/ch/',
}

export enum AccountUrl {
  DeleteAccount = 'moncompte/delete',
  ParentalCode = 'moncompte/code/parental',
  PurchaseCode = 'moncompte/code/purchase',
  PurchaseCodeDeletion = 'moncompte/code/purchase/delete',
  ViolentContent = 'moncompte/code/parental/violentcontent',
}

/** @public */
export const isAccountUrl = isSomeEnum(AccountUrl);

/**
 * Representation of supported queries values for buffer entry page
 */
export const BufferQueries = {
  Display: 'display',
  Logger: 'logger',
  MenuVersion: 'menuversion',
  Path: 'path',
  Resize: 'resize',
} as const;

export type BufferQueries = (typeof BufferQueries)[keyof typeof BufferQueries]; // eslint-disable-line @typescript-eslint/no-redeclare

export const Queries = {
  ...BufferQueries,
  Arbo: 'arbo',
  Buffer: 'buffer',
  Build: 'build',
  Code: 'code',
  Devtools: 'devtools',
  DisplayGDPR: 'displayGDPR',
  FeatureToggles: 'featureToggles',
  InitialHistoryEntries: 'history',
  OfferLocation: 'offerLocation',
  OfferZone: 'offerZone',
  PassAuthSuccessCallback: 'passAuthSuccessCallback',
  Platform: 'platform',
  RedirectUri: 'redirect_uri',
  RefreshRight: 'refreshRight',
  Token: 'token',
  VoucherKey: 'voucherKey',
  Channel: 'channel',
} as const;

export type Queries = (typeof Queries)[keyof typeof Queries]; // eslint-disable-line @typescript-eslint/no-redeclare
