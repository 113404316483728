import { getCookie, getUserDeviceKeys } from '@canalplus/mycanal-commons';
import { addHeader } from '@canalplus/mycanal-fetch';
import type { HodorSdkConfig } from '@canalplus/sdk-hodor';
import { HttpsProxyAgent } from 'https-proxy-agent';
import { XOR } from 'ts-essentials';
import { CookieKey } from '../../constants/cookie';
import { Queries } from '../../constants/url';
import { getPublicConfig } from '../../helpers/config/config-helper';
import Logger from '../../helpers/logger/logger-helper';
import { getClientSideUserInfos } from '../../helpers/user/user-helper';
import {
  appKeySelector,
  fullLocaleSelector,
  offerLocationSelector,
  offerZoneSelector,
  platformSelector,
  tokenCMSSelector,
  userStatusSelector,
} from '../../selectors/application/application-selectors';
import { isDebugRequest } from '../../server/helpers/isDebugRequest';
import { getPassToken } from '../../store/slices/user';
import {
  analyticsIdSelector,
  anonymousIdSelector,
  hasAnalyticsCollectedSelector,
  hasAnonymousTrackingSelector,
  macroEligibilitySelector,
  microEligibilitySelector,
  passTokenSelector,
  profileIdSelector,
} from '../../store/slices/user-selectors';
import type { IState } from '../../store/types/State-type';
import { HodorCinematicParametersServerSpecific } from './types';

type GetHodorSdkConfigParameters = {
  proxyAgent?: HttpsProxyAgent<string>;
} & XOR<{ dispatch: Redux.Dispatch; state: IState }, HodorCinematicParametersServerSpecific>;

type GetClientSideConfigParameters = {
  dispatch: Redux.Dispatch;
  state: IState;
};

/**
 * Every keys that depends on the render mode (CSR / SSR).
 *
 * - `arboVersion` can be null if not provided in the query params
 * - `appKey` can be undefined
 */
type HodorSdkConfigRenderModeSpecific = Pick<
  HodorSdkConfig,
  | 'acceptLanguage'
  | 'analyticsId'
  | 'anonymousId'
  | 'cmsToken'
  | 'deviceId'
  | 'isAnalyticsEnabled'
  | 'isAnonymousAnalyticsEnabled'
  | 'passToken'
  | 'passTokenRenewalCallback'
  | 'profileId'
  | 'requestId'
> & { appKey?: string; arboVersion?: string | null };

const getClientSideConfig = ({ dispatch, state }: GetClientSideConfigParameters): HodorSdkConfigRenderModeSpecific => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const userDeviceCookie = getCookie(CookieKey.DeviceId);
  const { trackingKey } = getUserDeviceKeys(userDeviceCookie);
  const fullLocale = fullLocaleSelector(state);

  const passTokenRenewalCallback = async () => {
    const { passToken } = await getClientSideUserInfos();

    dispatch(getPassToken(passToken));

    return passToken;
  };

  return {
    acceptLanguage: fullLocale || navigator.language,
    analyticsId: analyticsIdSelector(state),
    anonymousId: anonymousIdSelector(state),
    arboVersion: urlSearchParams.get(Queries.MenuVersion) || urlSearchParams.get(Queries.Arbo),
    deviceId: trackingKey, // ⚠ For Hodor init deviceId is tracking_key ⚠
    isAnalyticsEnabled: hasAnalyticsCollectedSelector(state),
    isAnonymousAnalyticsEnabled: hasAnonymousTrackingSelector(state),
    passToken: passTokenSelector(state),
    profileId: profileIdSelector(state)?.toString() || '0',
    cmsToken: tokenCMSSelector(state),
    passTokenRenewalCallback,
  };
};

const getServerSideConfig = ({
  request,
  state,
}: Required<
  Pick<GetHodorSdkConfigParameters, 'request'> & Pick<GetHodorSdkConfigParameters, 'state'>
>): HodorSdkConfigRenderModeSpecific => {
  const { locale, query, requestId, userData, cookies } = request;
  const { appKey } = locale || {};
  const { passToken } = userData || {};
  const { [Queries.Arbo]: arbo, [Queries.MenuVersion]: menuVersion } = query || {};
  const { [CookieKey.ProfileId]: profileId, [CookieKey.DeviceId]: userDeviceCookie } = cookies || {};
  const { trackingKey } = getUserDeviceKeys(userDeviceCookie);
  const fullLocale = fullLocaleSelector(state);

  return {
    acceptLanguage: fullLocale || request?.headers['accept-language'],
    analyticsId: analyticsIdSelector(state),
    anonymousId: anonymousIdSelector(state),
    appKey,
    arboVersion: menuVersion || arbo,
    deviceId: trackingKey, // ⚠ For Hodor init deviceId is tracking_key ⚠
    isAnalyticsEnabled: hasAnalyticsCollectedSelector(state),
    isAnonymousAnalyticsEnabled: hasAnonymousTrackingSelector(state),
    passToken,
    profileId: profileId || '0',
    requestId,
  };
};

/**
 * Retrieve an `HodorSdkConfig` object.\
 * Can be used both on server and client side.
 */
export const getHodorSdkConfig = ({
  dispatch,
  proxyAgent,
  request,
  state: argState,
}: GetHodorSdkConfigParameters): HodorSdkConfig => {
  const {
    api: {
      hodor: { defaultAppKey, baseUrl, paths, platforms, fetchOptions },
    },
  } = getPublicConfig();

  const state = request ? request.reduxStore.store.getState() : argState;

  const platform = platformSelector(state);
  const offerLocation = offerLocationSelector(state);
  const appKey = appKeySelector(state);

  if (platform === 'OrangeKey') {
    throw new Error('ApplicationContext OrangeKey is not supported');
  }

  const { arboVersion, device } = platforms[platform];

  const configRenderModeSpecific = request
    ? getServerSideConfig({ request, state: request.reduxStore.store.getState() })
    : getClientSideConfig({ dispatch, state: argState });

  const headers =
    request && isDebugRequest(request) ? addHeader(fetchOptions.headers, 'xx-debug', '0') : fetchOptions.headers;

  return {
    baseUrl,
    device,
    fetchOptions: {
      ...fetchOptions,
      ...(proxyAgent && { agent: proxyAgent }),
      headers,
    },
    logger: Logger,
    macros: macroEligibilitySelector(state),
    micros: microEligibilitySelector(state),
    offerLocation,
    offerZone: offerZoneSelector(state),
    paths,
    ...configRenderModeSpecific,
    appKey: configRenderModeSpecific.appKey || appKey || defaultAppKey,
    arboVersion: configRenderModeSpecific.arboVersion || arboVersion,
    logMetadata: {
      userStatus: userStatusSelector(state),
    },
  };
};
