import { Lang, OfferLocation } from '@canalplus/sdk-core';

export const LCID_BF = 'fr-bf';
export const LCID_BI = 'fr-bi';
export const LCID_BJ = 'fr-bj';
export const LCID_BL = 'fr-bl';
export const LCID_CD = 'fr-cd';
export const LCID_CF = 'fr-cf';
export const LCID_CG = 'fr-cg';
export const LCID_CH = 'fr-ch';
export const LCID_CHDE = 'de-ch';
export const LCID_CI = 'fr-ci';
export const LCID_CM = 'fr-cm';
export const LCID_CV = 'fr-cv';
export const LCID_DJ = 'fr-dj';
export const LCID_ET = 'en-et';
export const LCID_FR = 'fr-fr';
export const LCID_GA = 'fr-ga';
export const LCID_GH = 'fr-gh';
export const LCID_GM = 'fr-gm';
export const LCID_GN = 'fr-gn';
export const LCID_GP = 'fr-gp';
export const LCID_GQ = 'fr-gq';
export const LCID_GW = 'fr-gw';
export const LCID_IT = 'it-it';
export const LCID_MF = 'fr-mf';
export const LCID_MG = 'fr-mg';
export const LCID_ML = 'fr-ml';
export const LCID_MQ = 'fr-mq';
export const LCID_MR = 'fr-mr';
export const LCID_NC = 'fr-nc';
export const LCID_NE = 'fr-ne';
export const LCID_NG = 'fr-ng';
export const LCID_PF = 'fr-pf';
export const LCID_PL = 'pl-pl';
export const LCID_RE = 'fr-re';
export const LCID_RW = 'fr-rw';
export const LCID_SL = 'fr-sl';
export const LCID_SN = 'fr-sn';
export const LCID_TD = 'fr-td';
export const LCID_TG = 'fr-tg';
export const LCID_US = 'en-us';
export const LCID_YT = 'fr-yt';

export const LOC_FR = OfferLocation.fr;
export const LOC_IT = OfferLocation.it;

export const LANG_FR: Lang = 'fr';
