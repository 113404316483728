import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export type PurchaseCodeState = {
  initialized: boolean;
  active: boolean;
  error: string;
  purchaseCodeBrowsing: boolean;
  purchaseCodeStatus: {
    enabled: boolean;
    disabled: boolean;
  };
  purchaseInfo?: {
    purchaseId?: string;
    productPrice?: number;
    paymentMethod?: string;
    productCategory?: string;
  };
};

export const initialState: PurchaseCodeState = {
  initialized: false,
  active: false,
  error: '',
  purchaseCodeBrowsing: false,
  purchaseCodeStatus: {
    enabled: false,
    disabled: false,
  },
};

const purchaseCodeSlice = createSlice({
  name: 'purchaseCode',
  initialState,
  reducers: {
    /** Triggered when browsing in purchase pages is reset */
    resetPurchaseCodeBrowsing: (state) => {
      return {
        ...state,
        active: false,
        purchaseCodeBrowsing: false,
      };
    },

    /** Triggered when purchaseCode error is reset */
    resetPurchaseCodeError: (state) => {
      return {
        ...state,
        error: '',
      };
    },

    /** Triggered when view is authorized in purchase pages */
    updatePurchaseCodeActive: (state) => {
      return {
        ...state,
        active: true,
      };
    },

    /** Triggered when browsing is authorized in purchase pages */
    updatePurchaseCodeBrowsing: (state) => {
      return {
        ...state,
        purchaseCodeBrowsing: true,
      };
    },

    /** Triggered when an error occurred */
    updatePurchaseCodeError: (state, action: PayloadAction<PurchaseCodeState['error']>) => {
      return {
        ...state,
        error: action.payload,
      };
    },

    /** Triggered when the purchase code is updated */
    updatePurchaseCodeStatus: (state, action: PayloadAction<PurchaseCodeState['purchaseCodeStatus']>) => {
      return {
        ...state,
        initialized: true,
        purchaseCodeStatus: {
          ...action.payload,
        },
      };
    },

    /** Triggered when purchase a VOD content */
    updatePurchaseInfo: (state, action: PayloadAction<PurchaseCodeState['purchaseInfo']>) => {
      return {
        ...state,
        purchaseInfo: action.payload,
      };
    },
  },
});

export const {
  resetPurchaseCodeBrowsing,
  resetPurchaseCodeError,
  updatePurchaseCodeActive,
  updatePurchaseCodeBrowsing,
  updatePurchaseCodeError,
  updatePurchaseCodeStatus,
  updatePurchaseInfo,
} = purchaseCodeSlice.actions;

export const purchaseCodeReducer = purchaseCodeSlice.reducer;

export type PurchaseCodeActions = ReturnType<
  (typeof purchaseCodeSlice.actions)[keyof typeof purchaseCodeSlice.actions]
>;
