import axios, { AxiosError } from 'axios';
import {
  template,
  parseChannels,
  parseDate,
  isNullOrUndefined,
  isSafariEnv,
  userStorage,
  dataStorage,
  removeEmptySegmentsFromUrl,
} from '@canalplus/oneplayer-utils';
import { BusinessTypes } from '@canalplus/oneplayer-constants';
import {
  IInitLiveTVResponse,
  IInitLiveTVStreamToken,
  ILiveTvGroupType,
  IParsedChannels,
  ILiveConfig,
  IInitLiveTVData,
  IInitLiveTVUserProfile,
  IMappingConfig,
  TOfferLocation,
  TOfferZone,
  IFeatureFlags,
} from '@canalplus/oneplayer-types';

import { fetchConfig } from '../config/index';

const { DEFAULT_GROUP_TYPES, DEFAULT_OFFER_ZONE } = BusinessTypes;

// Update init live tv version when the return from initlivetv changes
// so we don't share a different return value with mycanal
export const INIT_LIVE_TV_VERSION = '1.3';

type TTmpConfig = Pick<ILiveConfig, 'init' | 'deviceId' | 'offerZone'>;

interface ITmpConfig extends TTmpConfig {
  offerLocation: TOfferLocation | null;
  liveTvGroupType?: ILiveTvGroupType | string;
}

/**
 *
 * @param streamToken
 */
function parseStreamToken(streamToken: IInitLiveTVStreamToken[]): {
  [key: string]: string;
} {
  return {
    [streamToken[0].Type]: streamToken[0].Value,
  };
}

/**
 * This is the base block of each LiveTV playback content.
 * The method is available under two possible modes:
 * - 'auto', The method is completely independant, with minimal input, will get the complete initLiveTV data.
 * This mode is toward an external usage of the OnePlayer, might get little bit heavier since more async requests will be made.
 * - 'manual', This method will ask for more input to get InitLiveTV data but is lighter in terms of async request.
 * This mode is toward an internal usage.
 * @param object A config and an userProfile options
 * @returns A promise with several usefull initLiveTV data.
 */

// TODO: if from refreshtoken add a param to prevent use of savedData

/**
 *
 * @param arg0 parameters used to fetchInitLiveTV
 * @param arg0.config config object to use
 * @param arg0.config.offerZone the zone of the user
 * @param arg0.config.offerLocation current offer loaction
 * @param arg0.config.env environement
 * @param arg0.config.init init config
 * @param arg0.config.deviceId deviceId config
 * @param arg0.config.liveTvGroupType liveTvGroupType config
 * @param arg0.config.deviceType type of the device
 * @param arg0.config.configBaseUrl config base url
 * @param arg0.config.context context config
 * @param arg0.config.featureFlags all featureFlags in config
 * @param arg0.userProfile info about he user
 * @param arg0.isRefreshTokenRequest is refresh token request or not
 * @param arg0.getLatestPassToken action to get latest pass token
 * @returns init live TV
 */
async function fetchInitLiveTV({
  config,
  userProfile,
  isRefreshTokenRequest,
  getLatestPassToken,
}: {
  config: {
    offerLocation: TOfferLocation | null;
    offerZone: TOfferZone;
    env?: 'preprod' | 'prod';
    init?: string;
    deviceId?: string;
    liveTvGroupType?: string;
    deviceType?: BusinessTypes.DEVICE_TYPES;
    configBaseUrl?: string | null;
    context?: keyof IMappingConfig;
    featureFlags?: IFeatureFlags;
  };
  userProfile: IInitLiveTVUserProfile;
  isRefreshTokenRequest?: boolean;
  getLatestPassToken?: () => string;
}): Promise<IInitLiveTVData> {
  if (isNullOrUndefined(userProfile.passToken)) {
    const reason = {
      code: 'LIVE-MISSING-PASSTOKEN',
      message: 'initLiveTV requires a passToken',
    };
    throw reason;
  }
  let { deviceId } = config;
  if (!deviceId) {
    // We only support web platforms when we don't have a deviceId
    deviceId = isSafariEnv() ? '32' : '3';
  }

  // Don't look for savedData when we are trying to get a new liveToken
  // Else we won't make the call to refresh the token!
  if (!isRefreshTokenRequest) {
    const savedData = await dataStorage.getSavedInitLiveTVData(
      userProfile.passToken,
      INIT_LIVE_TV_VERSION,
    );
    if (savedData) {
      return savedData;
    }
  }
  let tmpConfig: ITmpConfig;
  // Should only happens when called directly as a library/external-package
  // Should never happens if you are using an instance of the OnePlayer.
  if (!config.init) {
    const serverConfig = await fetchConfig({
      env: config.env || 'prod',
      offerZone: config.offerZone,
      deviceType: config.deviceType,
      configBaseUrl: config.configBaseUrl,
      context: config.context,
    });
    const { init, liveTvGroupType } = serverConfig.live;
    tmpConfig = {
      init,
      deviceId,
      offerZone: config.offerZone,
      offerLocation: config.offerLocation,
      liveTvGroupType:
        liveTvGroupType[config.offerZone as keyof ILiveTvGroupType],
    };
  } else {
    tmpConfig = {
      init: config.init,
      deviceId,
      offerZone: config.offerZone,
      offerLocation: config.offerLocation,
      liveTvGroupType: config.liveTvGroupType,
    };
  }

  const url = removeEmptySegmentsFromUrl(
    template(tmpConfig.init, {
      deviceId: tmpConfig.deviceId,
      offerZone: tmpConfig.offerZone || DEFAULT_OFFER_ZONE,
      offerLocation: tmpConfig.offerLocation || '',
    }),
  );
  let { userKeyId, deviceKeyId } = userProfile;
  if (!userKeyId || !deviceKeyId) {
    const { userKeyId: userKeyIdSaved, deviceKeyId: deviceKeyIdSaved } =
      await userStorage.getCredentials();
    userKeyId = userKeyIdSaved;
    deviceKeyId = deviceKeyIdSaved;
  }
  const body = {
    ServiceRequest: {
      InData: {
        UseRmuTokenPlaceHolder: true,
        PassData: {
          Id: 0,
          Token: userProfile.passToken,
        },
        UserKeyId: userKeyId,
        DeviceKeyId: deviceKeyId,
        PDSData: {
          // GroupTypes value:
          // 0: Default. Group of all the channels of the PDS (whether allowed or not).
          // 1: Group of channels allowed for the customer
          // 2: Group of channels not allowed for the customer
          // 4: Group for broadcasted event
          // must of the type GroupType: `1;4` | `1`
          GroupTypes: tmpConfig.liveTvGroupType || DEFAULT_GROUP_TYPES,
        },
      },
    },
  };

  let initLiveTVData: IInitLiveTVData;
  try {
    const response = await axios.post<IInitLiveTVResponse>(url, body);
    const initLiveTVResponse = response.data;

    const initLiveTVRawData = initLiveTVResponse.ServiceResponse.OutData;
    const liveTime = parseDate(initLiveTVResponse.ServiceResponse.NowUTC);

    if (!initLiveTVRawData) {
      const reason = {
        code: `LIVE${initLiveTVResponse.ServiceResponse.Status}`,
        message: 'initLiveTV Job throw an error',
      };
      throw reason;
    }

    const channelsMap = new Map<string, IParsedChannels>();
    const { ChannelsGroup } = initLiveTVRawData.PDS.ChannelsGroups;
    for (let i = 0; i < ChannelsGroup.length; i += 1) {
      const groupType = ChannelsGroup[i].GroupType;
      channelsMap.set(
        groupType,
        parseChannels(
          ChannelsGroup[i].Channels,
          groupType,
          initLiveTVRawData.RMUToken,
          config.featureFlags,
        ),
      );
    }

    if (channelsMap.size === 0) {
      const reason = {
        code: 'EMPTY-PDS',
        message: 'channels is empty',
      };
      throw reason;
    }

    const code: string | null = initLiveTVRawData?.User?.PCode ?? null;
    const encryptionMethod: string | null =
      initLiveTVRawData?.User?.EncryptionMethod ?? null;
    const salt: string | null = initLiveTVRawData?.User?.Salt ?? null;

    const streamToken = parseStreamToken(
      initLiveTVRawData.StreamTokens.StreamToken,
    );
    const liveToken = initLiveTVRawData.LiveToken;

    const rmuToken = initLiveTVRawData.RMUToken;

    initLiveTVData = {
      streamToken,
      liveToken,
      liveTime,
      parentalCode: { code, encryptionMethod, salt },
      channels: channelsMap,
      rmuToken,
    };

    await dataStorage.saveInitLiveTVData(
      initLiveTVData,
      getLatestPassToken?.() ?? userProfile.passToken,
      INIT_LIVE_TV_VERSION,
    );

    return initLiveTVData;
  } catch (error: unknown) {
    // If there is an error related to a timeout, meaning that the initLiveTv service may not be available
    if (
      (error as AxiosError).isAxiosError &&
      (error as AxiosError).code === 'ECONNABORTED'
    ) {
      // Get initLiveTvData preset from local storage
      const storedData = window.localStorage.getItem(
        `oneplayer:data:initLiveTVData:${INIT_LIVE_TV_VERSION}`,
      );

      if (storedData) {
        initLiveTVData = JSON.parse(storedData);

        // Return initLiveTVData as if the service was available
        return {
          ...initLiveTVData,
          channels: new Map(initLiveTVData.channels), // Format channels back to Map object
        };
      }
    }

    // In case there is an error we can't handle, we want to remove any data stored in local storage and throw the error
    dataStorage.removeItem(`initLiveTVData:${INIT_LIVE_TV_VERSION}`);
    throw error;
  }
}

export default fetchInitLiveTV;
