import { TPlatform } from '@canalplus/oneplayer-types';

export const PlayerPlatform = {
  Live: 'live',
  Hapi: 'hapi',
  Multi: 'multi',
  Directfile: 'directfile',
} satisfies Record<string, TPlatform>;

export type PlayerPlatform = (typeof PlayerPlatform)[keyof typeof PlayerPlatform]; // eslint-disable-line @typescript-eslint/no-redeclare
