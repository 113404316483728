import { Middleware } from 'redux';
import { requestTemplate } from '../slices/page';
import { resetPurchaseCodeBrowsing, updatePurchaseCodeActive } from '../slices/purchaseCode';
import { IState } from '../types/State-type';

/**
 * This middleware is in charge of detecting if the next
 * content will be purchase code or not. Depending on the case, it
 */
export const purchaseCodeMiddleware: Middleware<object, IState, Redux.Dispatch> =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const { purchaseCode } = getState();

    if (requestTemplate.match(action)) {
      // If next page has purchase in it...
      if (action.payload.purchaseCode) {
        // ...and purchaseCode is not active yet, we turn it on
        if (!purchaseCode.active) {
          dispatch(updatePurchaseCodeActive());
        }
      } else if (purchaseCode.active) {
        // If we are not on an purchase page...
        // ...and purchaseCode is active, we turn it off
        dispatch(resetPurchaseCodeBrowsing());
      } else if (!purchaseCode.active && purchaseCode.purchaseCodeBrowsing) {
        // If we are not on purchase page...
        // ...active is false and purchaseBrowsing is true , we turn it off
        dispatch(resetPurchaseCodeBrowsing());
      }
    }

    return next(action);
  };
