/* eslint-disable no-console */

class Logger {
  warnedMessage: string[];

  currentLevel: number;

  private static singleton: Logger;

  defaultLevel = 5;

  LEVELS = {
    LOG: 0,
    DEBUG: 1,
    INFO: 2,
    WARN: 3,
    ERROR: 4,
    OFF: 5,
  };

  constructor() {
    this.currentLevel = this.defaultLevel;
    this.warnedMessage = [];
  }

  static get instance(): Logger {
    if (!this.singleton) {
      console.debug('Logger > new instance');
      this.singleton = new Logger();
    }

    return this.singleton;
  }

  setLevel(value: unknown): void {
    if (typeof value === 'string') {
      this.currentLevel =
        this.LEVELS[
          value as 'LOG' | 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'OFF'
        ];
    } else if (typeof value === 'number') {
      this.currentLevel = value;
    }
  }

  isEnabled(level: number): boolean {
    return level >= this.currentLevel;
  }

  log(msg: unknown, ...args: unknown[]): void {
    const { LOG } = this.LEVELS;

    if (this.isEnabled(LOG)) {
      console.log(msg, ...args);
    }
  }

  debug(msg: unknown, ...args: unknown[]): void {
    const { DEBUG } = this.LEVELS;

    if (this.isEnabled(DEBUG)) {
      console.debug(msg, ...args);
    }
  }

  info(msg: unknown, ...args: unknown[]): void {
    const { INFO } = this.LEVELS;

    if (this.isEnabled(INFO)) {
      console.info(msg, ...args);
    }
  }

  /**
   * Warn the user when the level of log is set to WARN.
   * If u want to warn and got rid of the level of authorize,
   * u can use warnOnce, useful to deliver message through our app to our
   * developer user.
   *
   * @param msg
   * @param args
   */
  warn(msg: unknown, ...args: unknown[]): void {
    const { WARN } = this.LEVELS;

    if (this.isEnabled(WARN)) {
      console.warn(msg, ...args);
    }
  }

  /**
   * Warn user about a given message, but got rid of level of LOG.
   * This gives possibility to display message in PROD mode.
   * Usefull to give message to ppl that integrate us, because they will always
   * be using the PROD env where the LOG are disabled.
   * /!\ Will only warn user once during the life time of the application.
   *
   * @param message
   * @param args
   */
  warnOnce(message: string, ...args: unknown[]): void {
    if (!this.warnedMessage.includes(message)) {
      console.warn(message, ...args);
      this.warnedMessage.push(message);
    }
  }

  error(msg: unknown, ...args: unknown[]): void {
    const { ERROR } = this.LEVELS;

    if (this.isEnabled(ERROR)) {
      console.error(msg, ...args);
    }
  }
}

const logger = Logger.instance;

export { logger };
