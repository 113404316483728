import type { AllActionsTypes } from '../reducers/actions/All-actions-types';
import { setPersoUpdated } from '../slices/immersive';
import { startPlayer } from '../slices/player';

/**
 * Handles the side effects for contentGrid perso pages
 */
export const personalStratesMiddleware: Redux.Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    switch ((action as AllActionsTypes).type) {
      case startPlayer.type: {
        dispatch(setPersoUpdated(true));
        break;
      }

      default:
        break;
    }

    return next(action);
  };
