import { getCookie, getRootDomain, setCookie } from '@canalplus/mycanal-commons';
import Cookies from 'js-cookie';
import { CookieKey } from '../../constants/cookie';
import { getCookieOptions } from '../cookie/getCookieOptions';

/**
 * WhoIsWatching session expire in minutes
 */
const WHO_IS_WATCHING_SESSION_EXPIRE = 30;

export const getCookieWhoIsWatchingDisabled = (): boolean => {
  return getCookie(CookieKey.WhoIsWatchingDisabled) === 'true';
};

export const getCookieWhoIsWatchingSession = (): boolean => {
  return getCookie(CookieKey.WhoIsWatchingSession) === 'true';
};

export const setCookieWhoIsWatchingDisabled = (isTvDevice: boolean, disabled: boolean): void =>
  setCookie(
    CookieKey.WhoIsWatchingDisabled,
    disabled.toString(),
    getCookieOptions({
      sameSite: isTvDevice ? 'none' : 'Lax',
    })
  );

export const setCookieWhoIsWatchingSession = (isTvDevice: boolean): void =>
  setCookie(
    CookieKey.WhoIsWatchingSession,
    'true',
    getCookieOptions({
      sameSite: isTvDevice ? 'none' : 'Lax',
      expires: new Date(Date.now() + WHO_IS_WATCHING_SESSION_EXPIRE * 60 * 1000), // expire in 30 minutes,
    })
  );

export const removeAllCookiesWhoIsWatching = (): void => {
  const domain = getRootDomain(window.location.href);
  Cookies.remove(CookieKey.WhoIsWatchingDisabled, { domain });
  Cookies.remove(CookieKey.WhoIsWatchingSession, { domain });
};
