import { HodorSdkConfig, getMergedConfigWithAuthenticate, authenticate as sdkAuthenticate } from '@canalplus/sdk-hodor';
import { ApiV2Authenticate } from '@dce-front/hodor-types';
import { markPerformance } from '../../../helpers/performance/markPerformance';
import { rewriteObjectUrls } from '../../../helpers/proxyUrls/proxyUrls';
import { getFirstLevelPageList } from '../../../server/helpers/authenticate-helpers';
import {
  serviceAuthenticateSuccess,
  setHeaderLevelPageList,
  setLocaleFromHodor,
} from '../../../store/reducers/actions/Application-actions';
import { HodorCinematicParametersSpecific } from '../types';
import { getSdkAuthenticateParameters } from './getSdkAuthenticateParameters';
import { getUserMenuExtended } from './getUserMenuExtended';
import { getUserMenuLanguages } from './getUserMenuLanguages';

type PerformAuthenticateParameters = {
  configWithInit: HodorSdkConfig;
  isKidsProfile?: boolean;
  shouldCollectUserData?: boolean;
} & HodorCinematicParametersSpecific;

/**
 * - fetch `Authenticate` API
 * - transform the response to fit the Orange needs
 * - fetch `UserMenuExtended` API
 * - dispatch some values in redux store
 */
export async function performAuthenticate({
  configWithInit,
  request,
  store: argStore,
  isKidsProfile,
  shouldCollectUserData,
}: PerformAuthenticateParameters): Promise<ApiV2Authenticate> {
  const store = argStore || request.reduxStore.store;
  const state = store.getState();

  let authenticateResponse = await sdkAuthenticate(configWithInit, {
    ...getSdkAuthenticateParameters({
      ...(request ? { request } : { store: argStore, isKidsProfile, shouldCollectUserData }),
    }),
  });

  if ($_BUILD_DEVICE_ORANGEBOX) {
    authenticateResponse = rewriteObjectUrls(authenticateResponse);
  }

  markPerformance('hodor-authenticate-fetched', state);

  // Set first level page list from hodor arborescence
  const firstLevelPageList = getFirstLevelPageList(
    authenticateResponse.arborescence?.concat(authenticateResponse.userMenu || [])
  );

  store.dispatch(setHeaderLevelPageList(firstLevelPageList));

  // If hodor sends a locale, set it in store
  if (authenticateResponse.locale?.code) {
    store.dispatch(setLocaleFromHodor(authenticateResponse.locale.code));
  }

  const configWithAuthenticate = getMergedConfigWithAuthenticate(configWithInit, authenticateResponse);

  // Call to user menu extended returned by authenticate.json
  const userMenuExtended = await getUserMenuExtended({
    sdkConfig: configWithAuthenticate,
    state,
    userMenu: authenticateResponse.userMenu,
  });

  // get languages
  const userMenuLanguages = await getUserMenuLanguages(configWithAuthenticate, authenticateResponse.userMenu);

  store.dispatch(
    serviceAuthenticateSuccess({
      ...authenticateResponse,
      userMenuExtended: {
        ...userMenuExtended,
      },
      userMenuLanguages,
    })
  );

  return authenticateResponse;
}
